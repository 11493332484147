import { useQuery } from "@tanstack/react-query"
import Pagination from "../../components/shared/Pagination"
import { useState } from "react"
import { getAllSermons } from "../../services/sermons"
import SermonModal from "../../components/sermons/modal/SermonModal"
import SermonsTable from "../../components/sermons/table/SermonsTable"
import Loading from "../../components/shared/Loading"
import useDebounceState from "../../hooks/useDebounceState"
import SearchInput from "../../components/shared/SearchInput"

const SermonsListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddSermonModal, setShowAddSermonModal] = useState(false)

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["sermons", currentPage, search],
    queryFn: () => getAllSermons(currentPage, pageSize, search),
  })

  const pageSize = 5

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddSermonModal = () => setShowAddSermonModal(!showAddSermonModal)

  const totalCount = Data?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching sermons</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">خطب الجمعة</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={toggleAddSermonModal} className="px-4 py-2 btn-add">
            اضافة الخطبة
          </button>
        </div>
      </header>

      {showAddSermonModal && <SermonModal onClose={toggleAddSermonModal} />}
      {isLoading ? <Loading /> : <SermonsTable sermons={Data?.content || []} />}

      <div className=" mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default SermonsListPage
