import { useContext } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { UserContext } from "../context/AuthContext"

export const ProtectedRoute = ({ requiredRole }: { requiredRole?: string }) => {
  const { isAuthenticated, user } = useContext(UserContext)

  if (!isAuthenticated || user?.role.role === "USER") {
    // User not authenticated, redirect to login
    return <Navigate to="/login" />
  }

  if (requiredRole && user?.role.role !== requiredRole) {
    // User does not have the required role
    return <Navigate to="/" />
  }

  return <Outlet /> // Proceed with rendering child routes if authenticated and authorized
}
