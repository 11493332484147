import { QuestionsResponse } from "../types/questionTypes"
import apiClient from "../utils/api"

export const getAllQuestions = async (
  pageNo: number = 0,
  pageSize: number = 5,
): Promise<QuestionsResponse> => {
  const response = await apiClient.get(
    `/contact-us/admin?pageNo=${pageNo}&pageSize=${pageSize}`,
  )
  return response.data
}
