import { useContext, useState } from "react"
import { LivesData, LivesTableProps } from "../../../types/liveTypes"
import { deleteLive } from "../../../services/lives"
import { queryClient } from "../../../utils/reactQueryClient"
import useCustomMutation from "../../../hooks/useCustomMutation"
import DeleteButton from "../../shared/DeleteButton"
import LiveModal from "../modal/LiveModal"
import { FaCopy } from "react-icons/fa"
import { FaSquareCheck } from "react-icons/fa6"
import classNames from "classnames"
import { UserContext } from "../../../context/AuthContext"

const LivesTable: React.FC<LivesTableProps> = ({ lives }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [copied, setCopied] = useState(false)
  const [isDIsabled, setIsDisabled] = useState<number | null>(null)
  const [editingLive, setEditingLive] = useState<LivesData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeLive } = useCustomMutation({
    mutationFn: deleteLive,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["lives"] })
    },
  })

  const handleDelete = (liveId: string) => {
    removeLive(liveId)
  }

  const handleCopy = async (text: string, index: number) => {
    try {
      await navigator.clipboard.writeText(text)
      setIsDisabled(index)
      setCopied(true)
      setTimeout(() => setCopied(false), 3000)
    } catch (err) {
      console.error("Failed to copy text: ", err)
    }
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            {/* <th className="th-text">عنوان البث المباشر</th> */}
            <th className="th-text">تاريخ الإنشاء</th>
            <th dir="ltr" className="th-text w-[30%]">
              رمز الوصول
            </th>
            <th dir="ltr" className="th-text w-[30%]">
              رمز الوصول إلى البث المباشر
            </th>
            <th className="th-text">تاريخ انتهاء صلاحية الرمز</th>
            <th className="th-text text-center">تعديل</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {lives.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={4}>
                لا توجد بيانات{" "}
              </td>
            </tr>
          ) : (
            lives.map((live, index) => (
              <tr key={index} className="hover:bg-gray-50">
                {/* <td className="td-text">{live.title}</td> */}
                <td className="td-text">
                  {new Date(live.creationDate).toLocaleDateString("fr-EG")}
                </td>
                <td
                  dir="ltr"
                  className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[30%]"
                >
                  {live.fxExchangeToken.substring(0, 15) + "..."}
                </td>
                <td
                  dir="ltr"
                  className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[30%]"
                >
                  {live.accessToken.substring(0, 15) + "..."}
                </td>
                <td className="td-text">
                  {live.expiredDate
                    ? new Date(live.expiredDate).toLocaleDateString("fr-EG")
                    : "لا يوجد"}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    disabled={isDIsabled === index && copied}
                    onClick={() => handleCopy(live?.accessToken, index)}
                    className={classNames("btn-edit", {
                      "cursor-not-allowed border-none opacity-30 hover:border-none hover:bg-white":
                        isDIsabled === index && copied,
                    })}
                  >
                    {copied && isDIsabled === index ? (
                      <FaSquareCheck className="w-auto h-6 text-green-800" />
                    ) : (
                      <FaCopy className="w-5 h-5 " />
                    )}
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(live.id)}
                      itemId={live.id}
                      itemName={"البث المباشر"}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <LiveModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingLive(undefined)
          }}
          live={editingLive}
        />
      )}
    </div>
  )
}

export default LivesTable
