import { useQuery } from "@tanstack/react-query"
import { getListSort } from "../../services/sort"
import Loading from "../../components/shared/Loading"
import SortList from "../../components/setting/sort/SortList"

const SettingListPage = () => {
  const { data, isPending: isLoading } = useQuery({
    queryKey: ["sort"],
    queryFn: () => getListSort(),
  })

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">الإعددات</h1>
      </header>

      {isLoading ? (
        <Loading />
      ) : (
        <div className="bg-gray-100 py-12 pr-20">
          <SortList
            initialdateHijri={
              data?.dateHijri?.hijriAdjustment === 0
                ? data?.dateHijri?.hijriAdjustment
                : 1
            }
            initialItemsHome={data?.listSortHomeScreen || []}
            initialItemsSideBar={data?.initialItems || []}
          />
        </div>
      )}
    </div>
  )
}

export default SettingListPage
