import {
  CategoriesResponse,
  CategoryData,
  DefinitionData,
} from "../types/definitionsTypes"
import apiClient from "../utils/api"

export const getAllCategories = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<CategoriesResponse> => {
  const response = await apiClient.get(
    `/category/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addCategory = (categoryData: CategoryData) => {
  return apiClient.post("/category", categoryData)
}

export const editCategory = (categoryData: CategoryData) => {
  return apiClient.put(`/category/${categoryData.id}`, categoryData)
}

export const deleteCategory = (categoryId: string) => {
  return apiClient.delete(`/category/${categoryId}`)
}

export const getDefenitionsByCategoryId = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
) => {
  const response = await apiClient.get(
    `/definition/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addDefinition = async (data: DefinitionData) => {
  return apiClient.post("/definition", data)
}

export const editDefinition = async (data: DefinitionData) => {
  return apiClient.put(`/definition/${data?.id}`, data)
}

export const deleteDefinition = (definitionId: string) => {
  return apiClient.delete(`/definition/${definitionId}`)
}

export const getCountDashboard = async () => {
  const response = await apiClient.get(`/dashboard/count`)
  return response.data
}
