import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { getAllQuestions } from "../../services/questions"
import Pagination from "../../components/shared/Pagination"
import Loading from "../../components/shared/Loading"
import QuestionTable from "../../components/questions/table/QuestionTable"

const QuestionsListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const pageSize = 5

  const {
    data: questionData,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["questions", currentPage],
    queryFn: () => getAllQuestions(currentPage, pageSize),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalCount = questionData?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching Questions</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">أسئلتكم</h1>
      </header>

      {isLoading ? (
        <Loading />
      ) : (
        <QuestionTable questions={questionData?.content || []} />
      )}

      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default QuestionsListPage
