import apiClient from "../utils/api"
import { ToursData, ToursResponse } from "../types/toursTypes"

export const addTour = (ToursData: ToursData) => {
  return apiClient.post("/touristTrip", ToursData)
}

export const editTour = (ToursData: ToursData) => {
  return apiClient.put(`/touristTrip/${ToursData.id}`, ToursData)
}

export const deleteTour = (lessonId: string) => {
  return apiClient.delete(`/touristTrip/${lessonId}`)
}

export const getAllTours = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<ToursResponse> => {
  const response = await apiClient.get(
    `/touristTrip/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}
