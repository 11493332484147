import apiClient from "../utils/api"
import { AmdahsData, AmdahsResponse } from "../types/amdahTypes"

export const getAllAmdahs = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<AmdahsResponse> => {
  const response = await apiClient.get(
    `/amdah/all?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addAmdah = (amdahsData: AmdahsData) => {
  return apiClient.post("/amdah", amdahsData)
}

export const editAmdah = (amdahsData: AmdahsData) => {
  return apiClient.put(`/amdah/${amdahsData.id}`, amdahsData)
}

export const deleteAmdah = (amdahId: string) => {
  return apiClient.delete(`/amdah/${amdahId}`)
}
