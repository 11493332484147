import { useState } from "react"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"
import { useQuery } from "@tanstack/react-query"
import { getAllUsers } from "../../services/usersMangement"
import Loading from "../../components/shared/Loading"
import Pagination from "../../components/shared/Pagination"
import { HiOutlineFilter } from "react-icons/hi"
import { roleUser } from "../../constants"
import { GrUpdate } from "react-icons/gr"
import UsersTable from "../../components/userManagement/table/UsersTable"
import UserManagementModal from "../../components/userManagement/modal/UserManagementModal"

const UsersListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [role, setRole] = useState<string>("")

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const pageSize = 5

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["users", currentPage, search, role],
    queryFn: () => getAllUsers(currentPage, pageSize, search, role),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddUserModal = () => setShowAddUserModal(!showAddUserModal)

  const totalCount = Data?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching Users</div>
  }

  const handleUpdate = () => {
    setRole("")
    setSearch("")
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">لوحة المستخدمين</h1>
        <div className="flex justify-between items-center mt-5">
          <div className="flex justify-center items-center">
            <SearchInput
              searchValue={searchState}
              handleChange={(e) => setSearch(e.target.value)}
            />
            <div className="relative mt-1 mr-4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                <HiOutlineFilter className="h-4 w-auto text-gray-600" />
              </div>
              <label htmlFor="fileRole" className="sr-only">
                دور المستخدم
              </label>
              <select
                id="fileRole"
                name="role"
                value={role}
                className="block w-full border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-700 appearance-none py-2 pl-8 pr-3 cursor-pointer"
                onChange={(e) => setRole(e.target.value)}
              >
                <option value="">دور المستخدم </option>
                {roleUser?.map((role, index) => (
                  <option key={index} value={role.value}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button onClick={handleUpdate} className="p-2  btn-add">
            <GrUpdate className="h-5 w-5" />
          </button>
        </div>
      </header>

      <button onClick={toggleAddUserModal} className="px-4 py-2 btn-add">
        إضافة مستخدم
      </button>
      {isLoading ? <Loading /> : <UsersTable users={Data?.content || []} />}
      {showAddUserModal && <UserManagementModal onClose={toggleAddUserModal} />}
      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default UsersListPage
