import classNames from "classnames"
import { useForm } from "react-hook-form"
import { ToursData } from "../../../types/toursTypes"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { addTour, editTour } from "../../../services/tours"
import { queryClient } from "../../../utils/reactQueryClient"
import { useEffect } from "react"
import MultiFileUpload from "../../shared/MultiFileUpload"
import MyFroalaEditor from "../../shared/MyFroalaEditor"
import DatePicker from "../../shared/DatePicker"

interface TourModalProps {
  onClose: () => void
  tour?: ToursData | null
}

const TourModal: React.FC<TourModalProps> = ({ onClose, tour }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    watch,
  } = useForm<ToursData>()
  const { mutate: operateTour } = useCustomMutation({
    mutationFn: tour ? editTour : addTour,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tours"] })
      onClose()
    },
  })

  useEffect(() => {
    if (tour) {
      reset(tour)
    }
  }, [tour, reset])

  const onSubmit = (data: ToursData) => {
    operateTour({ ...data })
  }

  const handleFileUpload = (fileName: string[]) => {
    setValue("tripPictures", fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("tripPictures", { required: "رابط صور الرحلة او الجولة مطلوب" })
  }, [register])

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2"> {tour ? "تعديل  " : "إضافة "} </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                العنوان
              </label>
              <input
                {...register("title", { required: "هذا الحقل مطلوب" })}
                className={classNames("input", {
                  "input-invalid": errors.title,
                })}
                placeholder="العنوان"
              />
              {errors.title && (
                <p className="text-red-500 text-xs">{errors.title.message}</p>
              )}
            </div>
            <div>
              <label
                htmlFor="dateFriday"
                className="block text-sm font-medium text-gray-700"
              >
                تاريخ الجولة
              </label>
              <DatePicker
                date={watch("dateTrip")}
                setDate={(val) =>
                  setValue("dateTrip", val, { shouldValidate: true })
                }
              />
              {errors.dateTrip && (
                <p className="text-red-500 text-xs">
                  {errors.dateTrip.message}
                </p>
              )}
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                تحميل الصور و الفيديوهات
                <span className="text-xs text-red-500 pr-2">
                  ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
                </span>
              </label>
              <MultiFileUpload
                onFileUpload={(fileName) => handleFileUpload(fileName)}
                ImgUrls={tour?.tripPictures || []}
                error={errors.tripPictures}
                type={[".jpeg", ".jpg", ".png", ".mp4"]}
              />
              {errors.tripPictures && (
                <p className="text-red-500 text-xs">
                  {errors.tripPictures.message}
                </p>
              )}
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                المحتوى
              </label>
              <div className="max-w-full overflow-hidden">
                <MyFroalaEditor
                  value={watch("description")}
                  onChange={(val) => setValue("description", val)}
                  error={errors.description}
                />
              </div>
              {errors.description && (
                <p className="text-red-500 text-xs">
                  {errors.description.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex justify-between pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              onClick={onClose}
              type="button"
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default TourModal
