import apiClient from "../utils/api"
import { MethodsData, MethodsResponse } from "../types/methodsTypes"

export const getAllMethods = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<MethodsResponse> => {
  const response = await apiClient.get(
    `/ORADMethod/all?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addMethod = (methodsData: MethodsData) => {
  return apiClient.post("/ORADMethod", methodsData)
}

export const editMethod = (methodsData: MethodsData) => {
  return apiClient.put(`/ORADMethod/${methodsData.id}`, methodsData)
}

export const deleteMethod = (methodId: string) => {
  return apiClient.delete(`/ORADMethod/${methodId}`)
}
