import { useForm } from "react-hook-form"
import { LiveModalProps, LivesData } from "../../../types/liveTypes"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { addLive, editLive } from "../../../services/lives"
import { queryClient } from "../../../utils/reactQueryClient"
import { useEffect } from "react"
import classNames from "classnames"

const LiveModal: React.FC<LiveModalProps> = ({ onClose, live }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<LivesData>()

  const { mutate: operateLive } = useCustomMutation({
    mutationFn: live ? editLive : addLive,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["lives"] })
      onClose()
    },
  })

  useEffect(() => {
    if (live) {
      reset(live)
    }
  }, [live, reset])

  const onSubmit = (data: LivesData) => {
    operateLive(data)
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">
          {live ? "تعديل رمز الوصول " : "إضافة رمز الوصول جديد "}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor=""
              className="block text-sm font-medium text-gray-700"
            >
              رمز الوصول
            </label>
            <textarea
              {...register("fxExchangeToken", { required: "هذا الحقل مطلوب" })}
              id="fxExchangeToken"
              className={classNames("input h-32", {
                "input-invalid": errors.fxExchangeToken,
              })}
              placeholder="رمز الوصول"
            />
            {errors.fxExchangeToken && (
              <p className="text-red-500 text-xs">
                {errors.fxExchangeToken.message}
              </p>
            )}
          </div>
          <div className="flex justify-between pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LiveModal
