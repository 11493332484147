import { MdOutlineCancel } from "react-icons/md"
import { QuestionModalProps } from "../../../types/questionTypes"

const QuestionModal: React.FC<QuestionModalProps> = ({
  question,
  setQuestion,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-7 rounded-md w-full max-w-md">
        <div className="flex items-center">
          <button
            onClick={() => setQuestion(null)}
            className="hover:text-gray-500 duration-700"
          >
            <MdOutlineCancel className="h-6 w-auto" />
          </button>
          <h1 className=" text-2xl font-bold pr-5 text-[#8C6633]">
            {question?.subject}
          </h1>
        </div>
        <hr className="my-4" />
        <div className="flex items-center justify-center">
          <p>{question?.message}</p>
        </div>
      </div>
    </div>
  )
}

export default QuestionModal
