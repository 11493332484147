import { useState } from "react"
import Loading from "../../components/shared/Loading"
import ToursTable from "../../components/tours/table/ToursTable"
import { getAllTours } from "../../services/tours"
import { useQuery } from "@tanstack/react-query"
import TourModal from "../../components/tours/modal/TourModal"
import Pagination from "../../components/shared/Pagination"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"

const ToursListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddTourModal, setShowAddTour] = useState(false)
  const pageSize = 5

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["tours", currentPage, search],
    queryFn: () => getAllTours(currentPage, pageSize, search),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddTourModal = () => setShowAddTour(!showAddTourModal)

  const totalCount = Data?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching Tours</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">أنشطتنا و سياحاتنا</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={toggleAddTourModal} className="px-4 py-2 btn-add">
            اضافة
          </button>
        </div>
      </header>

      {showAddTourModal && <TourModal onClose={toggleAddTourModal} />}
      {isLoading ? <Loading /> : <ToursTable tours={Data?.content || []} />}

      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default ToursListPage
