import { useState } from "react"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"
import { getAllLessonCategories } from "../../services/lessons"
import { useQuery } from "@tanstack/react-query"
import LessonCategoryModal from "../../components/lessons/modal/LessonCategory"
import Loading from "../../components/shared/Loading"
import LessonCategorysList from "../../components/lessons/lessonCategorys/LessonCategorysList"
import Pagination from "../../components/shared/Pagination"

const LessonsCatergoryListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddLessonCategoryModal, setShowAddLessonCategoryModal] =
    useState(false)
  const pageSize = 5

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: lessonCategoriesData,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["lessonCategories", currentPage, search],
    queryFn: () => getAllLessonCategories(currentPage, pageSize, search),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalCount = lessonCategoriesData?.totalElements ?? 0
  const toggleAddLessonCategoryModal = () =>
    setShowAddLessonCategoryModal(!showAddLessonCategoryModal)

  if (isError) {
    return <div>Error fetching lessons</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">تصنيفات الدروس</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button
            onClick={toggleAddLessonCategoryModal}
            className="px-4 py-2 btn-add"
          >
            اضافة صنف
          </button>
        </div>
      </header>

      {showAddLessonCategoryModal && (
        <LessonCategoryModal onClose={toggleAddLessonCategoryModal} />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <LessonCategorysList
          lessonsCategorys={lessonCategoriesData?.content || []}
        />
      )}

      <div className=" mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default LessonsCatergoryListPage
