import { MdOutlineCancel } from "react-icons/md"
import { isValidUrl } from "../../../utils/functions"

interface ImageModalProps {
  onClose: () => void
  url: string
}

const ImageModal: React.FC<ImageModalProps> = ({ url, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-7 rounded-md w-full max-w-md">
        <div className="flex justify-between items-center">
          <button
            className="hover:text-gray-500 duration-700"
            onClick={onClose}
          >
            <MdOutlineCancel className="h-6 w-auto" />
          </button>
          <h1 className="text-2xl font-bold text-[#8C6633]">
            {url.length > 20 ? url.substring(0, 20) + "..." : url}
          </h1>
        </div>
        <hr className="my-4" />
        <div className="flex items-center justify-center">
          <img
            src={
              isValidUrl(url)
                ? url
                : `https://islamic.peaqock.com/api/storage/downloadFile/${url}`
            }
            className="w-full h-96 text-blue-500 rounded-md border-2 border-blue-400"
            alt="picture trip"
          />
        </div>
      </div>
    </div>
  )
}

export default ImageModal
