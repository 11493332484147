import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react"
import { useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { User } from "../types/authTypes"
import {
  getAccessToken,
  removeAccessToken,
  removeRefreshToken,
} from "../utils/auth-utilis"
import { getUserInfo } from "../services/auth"

type UserContextType = {
  user: User | null
  isAuthenticated: boolean
  isLoading: boolean
  setUser: Dispatch<SetStateAction<User | null>>
  setAuthenticated: Dispatch<SetStateAction<boolean>>
  logout: () => void
}

type UserContextProviderProps = {
  children: ReactNode
}

export const UserContext = createContext<UserContextType>({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  logout: () => {},
  setUser: () => {},
  setAuthenticated: () => {},
})

export const UserProvider = ({ children }: UserContextProviderProps) => {
  const [user, setUser] = useState<User | null>(null)
  const [isAuthenticated, setAuthenticated] = useState(!!getAccessToken())
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const {
    data: loginData,
    isError,
    isLoading: isUserLoading,
  } = useQuery({
    queryKey: ["user"],
    queryFn: getUserInfo,
    enabled: !!getAccessToken(),
  })

  useEffect(() => {
    setIsLoading(isUserLoading)
  }, [isUserLoading])

  useEffect(() => {
    if (loginData) {
      setUser(loginData)
      setAuthenticated(true)
    } else if (!isUserLoading) {
      setUser(null)
      setAuthenticated(false)
    }
  }, [loginData, isUserLoading])

  useEffect(() => {
    if (isError) {
      logout()
    }
  }, [isError])

  const logout = () => {
    setUser(null)
    setAuthenticated(false)
    removeAccessToken()
    removeRefreshToken()
    navigate("/")
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isAuthenticated,
        isLoading,
        logout,
        setUser,
        setAuthenticated,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
