import { useState } from "react"
import {
  ListHomeScreen,
  ListSideBar,
  SortListProps,
} from "../../../types/sortTypes"
import SortListHome from "./SortListHome"
import SortlListSidebar from "./SortlListSidebar"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { updateSortList } from "../../../services/sort"
import { queryClient } from "../../../utils/reactQueryClient"

const SortList: React.FC<SortListProps> = ({
  initialItemsSideBar,
  initialItemsHome,
  initialdateHijri,
}) => {
  const [sortedItemsHome, setSortedItemsHome] =
    useState<ListHomeScreen[]>(initialItemsHome)
  const [sortedItemsSideBar, setSortedItemsSideBar] =
    useState<ListSideBar[]>(initialItemsSideBar)
  const [firstdateHijri, setFirstdateHijri] = useState<number>(initialdateHijri)

  const { mutate: operateSort, isPending: isLoading } = useCustomMutation({
    mutationFn: updateSortList,
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ["sort"] })
      }
    },
  })

  const onSubmit = async () => {
    operateSort({
      initialItems: sortedItemsSideBar,
      listSortHomeScreen: sortedItemsHome,
      dateHijri: { hijriAdjustment: firstdateHijri },
    })
  }

  const handleItemsHomeChange = (newItems: ListHomeScreen[]) => {
    setSortedItemsHome(newItems)
  }

  const handleItemsSidebarChange = (newItems: ListSideBar[]) => {
    setSortedItemsSideBar(newItems)
  }

  return (
    <div className="flex flex-col ">
      <div className="w-full flex flex-row justify-center">
        <SortListHome
          onItemsChange={handleItemsHomeChange}
          listinitialItems={sortedItemsHome}
          initialdateHijri={firstdateHijri}
          onInputChange={(e: any) => setFirstdateHijri(Number(e?.target.value))}
        />
        <SortlListSidebar
          onItemsChange={handleItemsSidebarChange}
          listinitialItems={sortedItemsSideBar}
        />
      </div>

      <button
        onClick={onSubmit}
        type="submit"
        className="px-4 py-2 mt-4 w-[150px] bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
      >
        {isLoading ? "جاري التحميل..." : "حفظ"}
      </button>
    </div>
  )
}

export default SortList
