import { useQuery } from "@tanstack/react-query"
import Pagination from "../../components/shared/Pagination"
import { useState } from "react"
import { useParams } from "react-router-dom"
import ProgramModal from "../../components/programs/modal/ProgramModal"
import { getProgramByCategoryId } from "../../services/programs"
import ProgramsTable from "../../components/programs/table/ProgramsTable"
import Loading from "../../components/shared/Loading"
import BackButton from "../../components/shared/BackButton"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"

const ProgramsListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddProgramModal, setShowAddProgramModal] = useState(false)
  const { programCategoryId } = useParams()

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["programsByCategories", currentPage, search],
    queryFn: () =>
      getProgramByCategoryId(
        currentPage,
        pageSize,
        programCategoryId || "",
        search,
      ),
  })

  const pageSize = 5

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddProgramModal = () =>
    setShowAddProgramModal(!showAddProgramModal)

  const totalCount = Data?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching programs</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="title">برامج إعلامية</h1>
          <BackButton />
        </div>
        <div className="flex justify-between items-center">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={toggleAddProgramModal} className="px-4 py-2 btn-add">
            اضافة برنامج
          </button>
        </div>
      </header>

      {showAddProgramModal && (
        <ProgramModal
          programCategoryId={programCategoryId || ""}
          onClose={toggleAddProgramModal}
        />
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <ProgramsTable programs={Data?.content || []} />
      )}

      <div className=" mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default ProgramsListPage
