import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  MutationFunction,
} from "@tanstack/react-query"
import { toast } from "react-toastify"

interface CustomMutationOptions<TData, TError, TVariables, TContext>
  extends UseMutationOptions<TData, TError, TVariables, TContext> {
  mutationFn: MutationFunction<TData, TVariables>
}

function useCustomMutation<TData, TError, TVariables, TContext = unknown>(
  options: CustomMutationOptions<TData, TError, TVariables, TContext>,
): UseMutationResult<TData, TError, TVariables, TContext> {
  const { mutationFn, ...restOptions } = options

  return useMutation({
    ...restOptions,
    mutationFn,
    onSuccess: (data, variables, context) => {
      if (data && typeof data === "object" && "data" in data) {
        const messageData = data as { data: { message?: string } }
        const message = messageData.data?.message || "تم العملية بنجاح!"
        toast.success(message)
      } else {
        toast.success("تم العملية بنجاح!")
      }
      if (restOptions.onSuccess) restOptions.onSuccess(data, variables, context)
    },
    onError: (error, variables, context) => {
      toast.error("حدث خطأ ما.")
      if (restOptions.onError) restOptions.onError(error, variables, context)
    },
  })
}

export default useCustomMutation
