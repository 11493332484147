import { useQuery } from "@tanstack/react-query"
import Pagination from "../../components/shared/Pagination"
import { getDefenitionsByCategoryId } from "../../services/definitions"
import { useState } from "react"
import DefinitionsTable from "../../components/definitions/table/DefinitionsTable"
import DefinitionModal from "../../components/definitions/modal/DefinitionModal"
import Loading from "../../components/shared/Loading"
import BackButton from "../../components/shared/BackButton"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"

const DefinitionsListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddDefinitionModal, setShowAddDefinitionModal] = useState(false)

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["definitionBycategories", currentPage, search],
    queryFn: () => getDefenitionsByCategoryId(currentPage, pageSize, search),
  })

  const pageSize = 5

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddDefinitionModal = () =>
    setShowAddDefinitionModal(!showAddDefinitionModal)
  const totalCount = Data?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching defenitions</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header>
        <div className="flex justify-between items-center mb-8">
          <h1 className="title">التعريف بزاويتنا</h1>
          <BackButton />
        </div>
        <div className="flex justify-between items-center">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button
            onClick={toggleAddDefinitionModal}
            className="px-4 py-2 btn-add"
          >
            اضافة تعريف
          </button>
        </div>
      </header>

      {showAddDefinitionModal && (
        <DefinitionModal onClose={toggleAddDefinitionModal} />
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <DefinitionsTable definitions={Data?.content || []} />
      )}

      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default DefinitionsListPage
