import React, { ReactNode } from "react"
import Navbar from "./navbar/Navbar"
import Sidebar from "./sidebar/Sidebar"

interface MainLayoutProps {
  children: ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="flex w-screen h-screen ">
      <Sidebar />
      <div className="flex flex-col w-full">
        <Navbar />
        <main className="flex-1 bg-gray-50 py-8 px-4 sm:px-6 lg:px-8 w-[calc(100vw-16rm)]  h-[calc(100vh-4rem)] overflow-y-scroll custom-scrollbar">
          {children}
        </main>
      </div>
    </div>
  )
}

export default MainLayout
