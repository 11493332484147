import apiClient from "../utils/api"
import {
  ProgramCategoriesResponse,
  ProgramCategoryData,
  ProgramData,
} from "../types/programsTypes"

export const getAllProgramCategories = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<ProgramCategoriesResponse> => {
  const response = await apiClient.get(
    `/programCategories/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addProgramCategory = (
  programCategoryData: ProgramCategoryData,
) => {
  return apiClient.post("/programCategories", programCategoryData)
}

export const editProgramCategory = (
  programCategoryData: ProgramCategoryData,
) => {
  return apiClient.put(
    `/programCategories/${programCategoryData.id}`,
    programCategoryData,
  )
}

export const deleteProgramCategory = (programCategoryId: string) => {
  return apiClient.delete(`/programCategories/${programCategoryId}`)
}

export const getProgramByCategoryId = async (
  pageNo: number = 0,
  pageSize: number = 5,
  programCategoryId: string,
  search: string,
) => {
  const response = await apiClient.get(
    `/programs/${programCategoryId}/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addProgram = async (data: ProgramData) => {
  return apiClient.post("/programs", data)
}

export const editProgram = async (data: ProgramData) => {
  return apiClient.put(`/programs/${data.id}`, data)
}

export const deleteProgram = (programId: string) => {
  return apiClient.delete(`/programs/${programId}`)
}
