import { IoIosSearch } from "react-icons/io"
import { SearchInputProps } from "../../types/searhTypes"

const SearchInput: React.FC<SearchInputProps> = ({
  searchValue,
  handleChange,
}) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <IoIosSearch className="w-7 h-7 pl-2 text-gray-600" />
      </div>
      <input
        value={searchValue}
        onChange={handleChange}
        type="search"
        id="default-search"
        className="block w-full ps-10 input"
        placeholder="بحث..."
      />
    </div>
  )
}

export default SearchInput
