import { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { MdOutlineDragIndicator } from "react-icons/md"
import { SortListSidebarProps } from "../../../types/sortTypes"

const SortlListSidebar: React.FC<SortListSidebarProps> = ({
  listinitialItems,
  onItemsChange,
}) => {
  const [items, setItems] = useState(listinitialItems)

  useEffect(() => {
    onItemsChange(items)
  }, [items, onItemsChange])

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return
    const reorderedItems = Array?.from(items)
    const [reorderedItem] = reorderedItems.splice(result?.source?.index, 1)
    reorderedItems?.splice(result?.destination?.index, 0, reorderedItem)
    setItems(reorderedItems)
  }

  return (
    <div className="w-full">
      <h2 className="text-xl font-bold mb-4">ترتيب الشريط الجانبي</h2>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <ul
              className="bg-white p-4 rounded shadow-lg max-w-sm w-full text-right"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      className="flex flex-row items-center bg-gray-200 mb-2 py-2 rounded cursor-move"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="px-1 py-2 bg-gradient-to-r from-[#1E8A75] to-[#182A26] rounded-l-xl">
                        <p className="text-white font-bold">{index + 1}</p>
                      </div>
                      <MdOutlineDragIndicator className="text-gray-500 w-8 h-8" />
                      <p className="pr-4">{item.content}</p>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default SortlListSidebar
