import { useContext, useState } from "react"
import { MdEdit } from "react-icons/md"
import DeleteButton from "../../shared/DeleteButton"
import { ToursData, ToursTableProps } from "../../../types/toursTypes"
import { deleteTour } from "../../../services/tours"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import TourModal from "../modal/TourModal"
import Carousel from "./Carousel"
import { filterByExtensions, limitContent } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"

const ToursTable: React.FC<ToursTableProps> = ({ tours }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingTour, setEditingTour] = useState<ToursData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeTour } = useCustomMutation({
    mutationFn: deleteTour,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tours"] })
    },
  })

  const handleEdit = (tour: ToursData) => {
    setEditingTour(tour)
    setIsModalOpen(true)
  }

  const handleDelete = (tourId: string) => {
    removeTour(tourId)
  }

  return (
    <div className="overflow-x-auto mt-6 ">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text w-[30%]">
              صور
            </th>
            <th scope="col" className="th-text">
              العنوان
            </th>
            <th className="th-text w-[50%]">المحتوى</th>

            <th scope="col" className="th-text text-center">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {tours?.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={5}>
                لا توجد بيانات{" "}
              </td>
            </tr>
          ) : (
            tours?.map((tour, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="td-text w-[30%]">
                  <Carousel
                    tour={filterByExtensions(tour.tripPictures, [
                      ".jpeg",
                      ".png",
                    ])}
                  />
                </td>
                <td className="td-text">{tour?.title}</td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  {limitContent(tour.description)}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button onClick={() => handleEdit(tour)} className="btn-edit">
                    <MdEdit className="w-5 h-5" />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(tour.id)}
                      itemId={tour.id}
                      itemName={tour.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {isModalOpen && (
        <TourModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingTour(undefined)
          }}
          tour={editingTour}
        />
      )}
    </div>
  )
}

export default ToursTable
