import React, { useEffect, useState } from "react"
import apiClient from "../../utils/api"
import { MdOutlineAudioFile, MdVideoFile, MdPictureAsPdf } from "react-icons/md"
import classNames from "classnames"
import Player from "./Player"

interface FileUploadProps {
  onFileUpload: (fileUrl: FileUploadResponse) => void
  fileUrl: any
  fileType: any
  error: any
}
interface FileUploadResponse {
  fileName: string
  fileType: string
}

const UploadFile: React.FC<FileUploadProps> = ({
  onFileUpload,
  fileUrl,
  fileType,
  error,
}) => {
  const [uploading, setUploading] = useState(false)
  const [fileName, setNameFile] = useState(fileUrl)
  const [typeFile, setTypeFile] = useState(fileType)
  const [play, setPlay] = useState<string | null>(null)
  const [errorFile, setErrorFile] = useState<string | null>(null)

  const acceptedTypes = fileType === "VIDEO" ? ["video/mp4"] : ["audio/mpeg"]

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]

      if (!acceptedTypes.includes(file.type)) {
        setErrorFile("نوع الملف غير صالح")
        return
      }

      const formData = new FormData()
      formData.append("file", file)
      setErrorFile(null)
      setUploading(true)
      try {
        const response = await apiClient.post("/storage/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        const fileName = {
          fileName: response.data.fileName,
          fileType: response.data.fileType,
        }
        onFileUpload(fileName)
        setNameFile(fileName?.fileName)
        setTypeFile(fileName?.fileType)
      } catch (error) {
        console.error("Error uploading file:", error)
      } finally {
        setUploading(false)
      }
    }
  }

  return (
    <div className={classNames("input py-3", { "input-invalid": error })}>
      <div className="flex items-center">
        <label className="cursor-pointer bg-gradient-to-r from-green-600 to-green-900 hover:bg-green-800 text-white py-1 px-4 rounded-lg shadow-lg">
          <span>اختر الملف</span>
          <input
            type="file"
            accept=".mp3,.mp4"
            onChange={handleFileChange}
            disabled={uploading}
            className="hidden"
          />
        </label>
        <div>
          {errorFile && (
            <p className="text-red-600 text-sm pr-11">{errorFile}</p>
          )}
          {uploading && (
            <p className="text-green-600 pr-11">جاري التحميل ...</p>
          )}
        </div>
      </div>

      {fileName ? (
        <div className="flex justify-between items-center bg-blue-200 bg-opacity-50 shadow-md rounded-xl p-2 hover:bg-opacity-100 transition-colors duration-300 mt-4">
          {fileName?.length > 20 ? fileName.substring(0, 20) + "..." : fileName}
          {typeFile === "VIDEO" ? (
            <MdVideoFile
              onClick={() => setPlay(fileName)}
              className="w-8 h-8  text-blue-500 p-1 hover:scale-150 transition-all duration-700mr-4 cursor-pointer"
            />
          ) : (
            <MdOutlineAudioFile
              onClick={() => setPlay(fileName)}
              className="w-8 h-8  text-blue-500 p-1 hover:scale-150 transition-all duration-700mr-4 cursor-pointer"
            />
          )}
        </div>
      ) : (
        <div className="mt-4 bg-blue-200 bg-opacity-50 shadow-md rounded-xl p-2 hover:bg-opacity-100 transition-colors duration-300">
          <p className="text-base text-blue-900">لم يتم رفع أي ملف</p>
        </div>
      )}
      {play && <Player url={play} onClose={() => setPlay(null)} />}
    </div>
  )
}

export default UploadFile
