import { useForm } from "react-hook-form"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import { useEffect } from "react"
import classNames from "classnames"
import UploadImage from "../../shared/UploadImage"
import { AmdahModalProps, AmdahsData } from "../../../types/amdahTypes"
import { addAmdah, editAmdah } from "../../../services/amdah"
import UploadFile from "../../shared/UploadFile"

const AmdahModal: React.FC<AmdahModalProps> = ({ onClose, amdah }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
  } = useForm<AmdahsData>()

  const { mutate: operateAmdah } = useCustomMutation({
    mutationFn: amdah ? editAmdah : addAmdah,
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ["amdah"] })
        onClose()
      }
    },
  })

  const onSubmit = async (data: AmdahsData) => {
    operateAmdah(data)
  }

  const handleFileUpload = (
    fileName: string,
    field: "imgCover" | "lessonUrl",
  ) => {
    setValue(field, fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("imgCover", { required: "رابط صورة الغلاف مطلوب" })
    register("lessonUrl", { required: "المحتوى مطلوب" })
  }, [register])

  useEffect(() => {
    if (amdah) {
      reset(amdah)
    }
  }, [amdah, reset])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">{amdah ? " تعديل  " : "إضافة  "}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              العنوان
            </label>
            <input
              {...register("title", { required: "العنوان مطلوب" })}
              id="title"
              className={classNames("input", { "input-invalid": errors.title })}
              placeholder="العنوان"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              تحميل صورةالواجهة
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileUrl) =>
                handleFileUpload(fileUrl.fileName, "imgCover")
              }
              ImgUrl={amdah?.imgCover || null}
              error={errors?.imgCover}
              setError={(err) =>
                setError("imgCover", { type: "invalid", message: err })
              }
            />
            {errors?.imgCover && (
              <p className="text-red-500 text-xs">
                {errors?.imgCover?.message}
              </p>
            )}
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              تحميل محتوى
            </label>
            <UploadFile
              onFileUpload={(fileUrl) =>
                handleFileUpload(fileUrl.fileName, "lessonUrl")
              }
              fileType={"AUDIO"}
              fileUrl={amdah?.lessonUrl || null}
              error={errors.lessonUrl}
            />
            {errors?.lessonUrl && (
              <p className="text-red-500 text-xs">
                {errors?.lessonUrl?.message}
              </p>
            )}
          </div>

          <div className="flex justify-between space-x-reverse space-x-4 pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AmdahModal
