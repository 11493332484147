import { useState } from "react"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"
import { useQuery } from "@tanstack/react-query"
import { getAllMethods } from "../../services/methods"
import MethodModal from "../../components/methods/modal/MethodModal"
import Loading from "../../components/shared/Loading"
import Pagination from "../../components/shared/Pagination"
import MethodsTable from "../../components/methods/table/MethodTable"

const MethodsListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddMethodsModal, setShowAddMethodsModal] = useState(false)
  const pageSize = 5

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["methods", currentPage, search],
    queryFn: () => getAllMethods(currentPage, pageSize, search),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddMethodsModal = () =>
    setShowAddMethodsModal(!showAddMethodsModal)
  const totalCount = Data?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching Methods</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">أوراد وأذكار زاويتنا</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={toggleAddMethodsModal} className="px-4 py-2 btn-add">
            اضافة
          </button>
        </div>
      </header>
      {isLoading ? <Loading /> : <MethodsTable methods={Data?.content || []} />}
      {showAddMethodsModal && <MethodModal onClose={toggleAddMethodsModal} />}
      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default MethodsListPage
