import React from "react"
import { FaAngleRight, FaAngleLeft } from "react-icons/fa"

interface PaginationProps {
  currentPage: number
  totalCount: number
  pageSize: number
  onPageChange: (page: number) => void
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize)

  const handlePrevious = () => {
    onPageChange(Math.max(0, currentPage - 1))
  }

  const handleNext = () => {
    onPageChange(Math.min(totalPages - 1, currentPage + 1))
  }

  return (
    <div className="flex justify-center items-center space-x-2 rtl:space-x-reverse">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 0}
        className="flex items-center px-4 py-2 btn-add disabled:bg-gray-300"
      >
        <FaAngleRight className="text-lg" />
      </button>

      <div className="flex-1 text-center">
        <span>
          الصفحة {currentPage + 1} من {totalPages}
        </span>
      </div>

      <button
        onClick={handleNext}
        disabled={currentPage === totalPages - 1}
        className="flex items-center px-4 py-2 btn-add disabled:bg-gray-300"
      >
        <FaAngleLeft className="text-lg" />
      </button>
    </div>
  )
}

export default Pagination
