import { useState } from "react"
import useDebounceState from "../../hooks/useDebounceState"
import SearchInput from "../../components/shared/SearchInput"
import { useQuery } from "@tanstack/react-query"
import { getAllActualitys } from "../../services/actuality"
import ActualityModal from "../../components/actuality/modal/ActualityModal"
import Loading from "../../components/shared/Loading"
import ActualityTable from "../../components/actuality/table/ActualityTable"
import Pagination from "../../components/shared/Pagination"

const ActualityListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddActualityModal, setShowAddActualityModal] = useState(false)
  const pageSize = 5

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: actualityData,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["actuality", currentPage, search],
    queryFn: () => getAllActualitys(currentPage, pageSize, search),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalCount = actualityData?.totalElements ?? 0
  const toggleAddActualityModal = () =>
    setShowAddActualityModal(!showAddActualityModal)

  if (isError) {
    return <div>Error fetching Actuality</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">مستجدات</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button
            onClick={toggleAddActualityModal}
            className="px-4 py-2 btn-add"
          >
            اضافة مستجد
          </button>
        </div>
      </header>

      {showAddActualityModal && (
        <ActualityModal onClose={toggleAddActualityModal} />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <ActualityTable actualitys={actualityData?.content || []} />
      )}

      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default ActualityListPage
