import { useContext, useState } from "react"
import { UsersData, UsersTableProps } from "../../../types/userManagementTypes"
import { deleteUser, lockedUser } from "../../../services/usersMangement"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import DeleteButton from "../../shared/DeleteButton"
import UserManagementModal from "../modal/UserManagementModal"
import { UserContext } from "../../../context/AuthContext"

const UsersTable: React.FC<UsersTableProps> = ({ users }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isDisabled, setIsDisabled] = useState<number | null>(null)
  const [editingUser, setEditingUser] = useState<UsersData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeUser } = useCustomMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    },
  })

  const { mutate: disableUser, isPending: isLoading } = useCustomMutation({
    mutationFn: lockedUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    },
  })

  const handleDelete = (user: UsersData) => {
    removeUser(user)
  }

  const handleInableUser = (user: UsersData, index: number) => {
    disableUser(user)
    setIsDisabled(index)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الإسم
            </th>
            <th scope="col" className="th-text">
              النسب
            </th>
            <th scope="col" className="th-text w-[50%]">
              دور المستخدم
            </th>
            <th scope="col" className="th-text w-[50%]">
              وضعية المستخدم
            </th>
            {isSuperAdmin && (
              <th scope="col" className="th-text text-center">
                تعديل
              </th>
            )}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {users?.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={5}>
                لا توجد بيانات
              </td>
            </tr>
          ) : (
            users?.map((user, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="td-text">{user.firstname}</td>
                <td className="td-text">{user.lastname}</td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  {user.role?.role === "USER" ? "مستخدم" : "مشرف"}
                </td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  <button
                    onClick={() => handleInableUser(user, index)}
                    disabled={isLoading && index === isDisabled}
                    className={`px-4 py-2 rounded-lg transition-colors duration-300 ease-in-out ${user?.locked ? "bg-red-500 hover:bg-red-600 text-white" : "bg-green-500 hover:bg-green-600 text-white"} ${isLoading && index === isDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                  >
                    {user?.locked ? "فتح" : "قفل"}
                  </button>
                </td>
                {isSuperAdmin && (
                  <td className="whitespace-nowrap text-left px-6 py-3">
                    <DeleteButton
                      onDelete={() => handleDelete(user)}
                      itemId={user.id}
                      itemName={user.firstname}
                    />
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <UserManagementModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingUser(undefined)
          }}
          user={editingUser}
        />
      )}
    </div>
  )
}

export default UsersTable
