import { Routes, Route, Navigate } from "react-router-dom"
import LoginPage from "../pages/auth/LoginPage"
import LayoutWrapper from "../components/layout/LayoutWrapper"
import HomePage from "../pages/home/HomePage"
import { ProtectedRoute } from "./ProtectedRoute"
import DefinitionsListPage from "../pages/definitions/DefinitionsListPage"
import LessonsListPage from "../pages/lessons/LessonsListPage"
import ProgramsPage from "../pages/programs/ProgramsPage"
import ProgramsListPage from "../pages/programs/ProgramsListPage"
import SermonsListPage from "../pages/sermons/SermonsListPage"
import ToursListPage from "../pages/tours/ToursListPage"
import MethodsListPage from "../pages/methods/MethodsListPage"
import ActualityListPage from "../pages/actuality/ActualityListPage"
import UsersListPage from "../pages/userManagement/UsersListPage"
import NotificationsListPage from "../pages/notification/NotificationsListPage"
import LivesListPage from "../pages/liveStreaming/LivesListPage"
import SettingsListPage from "../pages/setting/SettingListPage"
import QuestionsListPage from "../pages/questions/QuestionsListPage"
import AmdahListPage from "../pages/amdah/AmdahListPage"
import LessonsCatergoryListPage from "../pages/lessons/LessonsCatergoryListPage"

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<LayoutWrapper />}>
          <Route index element={<HomePage />} />
          <Route path="actuality" element={<ActualityListPage />} />
          <Route path="definitions" element={<DefinitionsListPage />} />
          <Route path="methods" element={<MethodsListPage />} />
          {/* <Route path="sanad-zawitina" element={<SanadListPage />} /> */}
          <Route
            path="educational-lessons"
            element={<LessonsCatergoryListPage />}
          />
          <Route
            path="educational-lessons/:lessoncategoryId"
            element={<LessonsListPage />}
          />
          <Route path="friday-sermons" element={<SermonsListPage />} />
          <Route path="scientific-programs" element={<ProgramsPage />} />
          <Route
            path="scientific-programs/:programCategoryId"
            element={<ProgramsListPage />}
          />
          <Route path="tours" element={<ToursListPage />} />
          <Route path="live-broadcast" element={<LivesListPage />} />
          <Route path="user-management" element={<UsersListPage />} />
          <Route path="notofications" element={<NotificationsListPage />} />
          <Route path="questions" element={<QuestionsListPage />} />
          <Route path="settings" element={<SettingsListPage />} />
          <Route path="amdahs" element={<AmdahListPage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}

export default AppRoutes
