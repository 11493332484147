import React from "react"
import { useQuery } from "@tanstack/react-query"
import Pagination from "../../components/shared/Pagination"
import { useState } from "react"
import AddProgramCategoryModal from "../../components/programs/modal/ProgramCategoryModal"
import { getAllProgramCategories } from "../../services/programs"
import ProgramCategoriesList from "../../components/programs/programCategories/ProgramCategoriesList"
import Loading from "../../components/shared/Loading"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"

const ProgramsPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddProgramCategoryModal, setShowAddProgramCategoryModal] =
    useState(false)

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: programCategoriesData,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["programCategories", currentPage, search],
    queryFn: () => getAllProgramCategories(currentPage, pageSize, search),
  })

  const pageSize = 5

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalCount = programCategoriesData?.totalElements ?? 0
  const toggleAddProgramCategoryModal = () =>
    setShowAddProgramCategoryModal(!showAddProgramCategoryModal)

  if (isError) {
    return <div>Error fetching categories</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">تصنيفات البرامج</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button
            onClick={toggleAddProgramCategoryModal}
            className="px-4 py-2 btn-add"
          >
            اضافة صنف
          </button>
        </div>
      </header>

      {showAddProgramCategoryModal && (
        <AddProgramCategoryModal onClose={toggleAddProgramCategoryModal} />
      )}
      {isLoading ? (
        <Loading />
      ) : (
        <ProgramCategoriesList programCategories={programCategoriesData} />
      )}

      <div className=" mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default ProgramsPage
