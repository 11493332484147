import { LoginType, SendCodeType, User } from "../types/authTypes"
import apiClient from "../utils/api"
import {
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from "../utils/auth-utilis"
import axios from "axios"

const baseURL = process.env.REACT_APP_API_URL

export const signIn = (data: LoginType) => {
  return axios.post(`${baseURL}/auth/login`, data)
}

export const sendCode = (data: SendCodeType) => {
  return axios.post(`${baseURL}/auth/send-code`, data)
}

export const refreshToken = async () => {
  const refresh_Token = getRefreshToken()
  if (!refresh_Token) throw new Error("No refresh token available")

  try {
    const response = await axios.post(`${baseURL}/auth/refresh`, {
      refresh_token: refresh_Token,
    })
    const { access_token, refresh_token } = response.data
    setAccessToken(access_token)
    setRefreshToken(refresh_token)
    return access_token
  } catch (error) {
    console.error("Failed to refresh token", error)
    removeAccessToken()
    removeRefreshToken()
    throw error
  }
}

export const getUserInfo = async (): Promise<User> => {
  const response = await apiClient.get("/users/userinfo")
  return response.data
}
