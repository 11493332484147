import { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import Pagination from "../../components/shared/Pagination"
import { getLessonsByCategoryId } from "../../services/lessons"
import LessonsTable from "../../components/lessons/table/LessonsTable"
import LessonModal from "../../components/lessons/modal/LessonModal"
import Loading from "../../components/shared/Loading"
import useDebounceState from "../../hooks/useDebounceState"
import SearchInput from "../../components/shared/SearchInput"
import { HiOutlineFilter } from "react-icons/hi"
import { GrUpdate } from "react-icons/gr"
import { formatDateToISO } from "../../utils/functions"
import { lessonsType } from "../../constants"
import { useParams } from "react-router-dom"
import BackButton from "../../components/shared/BackButton"

interface Filter {
  startDate: string
  endDate: string
  type: string
}

const LessonsListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddDefinitionModal, setShowAddDefinitionModal] = useState(false)
  const { lessoncategoryId } = useParams()

  const [filter, setFilter] = useState<Filter>({
    startDate: "",
    endDate: "",
    type: "",
  })

  const { startDate, endDate, type } = filter
  const fromDate = formatDateToISO(startDate)
  const toDate = formatDateToISO(endDate)

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["lessons", currentPage, search, type, fromDate, toDate],
    queryFn: () =>
      getLessonsByCategoryId(
        lessoncategoryId || "",
        currentPage,
        pageSize,
        search,
        type,
        fromDate,
        toDate,
      ),
  })

  const pageSize = 5

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddDefinitionModal = () =>
    setShowAddDefinitionModal(!showAddDefinitionModal)
  const totalCount = Data?.totalElements ?? 0

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }))
  }

  useEffect(() => {
    setCurrentPage(0)
  }, [type])

  const handleUpdate = () => {
    setFilter({ startDate: "", endDate: "", type: "" })
    setSearch("")
  }

  if (isError) {
    return <div>Error fetching lessons</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="title">الدروس</h1>
          <BackButton />
        </div>

        <div className="flex justify-between items-center mt-5">
          <div className="flex justify-center items-center">
            <SearchInput
              searchValue={searchState}
              handleChange={(e) => setSearch(e.target.value)}
            />
            <div className="relative mt-1 mr-4">
              <div className="absolute inset-y-0 left-0 flex items-center pl-2 pointer-events-none">
                <HiOutlineFilter className="h-4 w-auto text-gray-600" />
              </div>
              <label htmlFor="fileType" className="sr-only">
                نوع الملف
              </label>
              <select
                id="fileType"
                name="type"
                value={type}
                className="block w-full border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-700 appearance-none py-2 pl-8 pr-3 cursor-pointer"
                onChange={handleChange}
              >
                <option value="">نوع الملف</option>
                {lessonsType?.map((type, index) => (
                  <option key={index} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center mr-6">
              <div className="relative">
                <input
                  name="startDate"
                  type="text"
                  value={startDate || ""}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  className="input"
                  placeholder="حدد تاريخ البدء"
                  onChange={handleChange}
                />
              </div>
              <span className="mx-4 text-gray-500">إلى</span>
              <div className="relative">
                <input
                  name="endDate"
                  type="text"
                  value={endDate || ""}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  className="input"
                  placeholder="حدد تاريخ الانتهاء"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <button onClick={handleUpdate} className="p-2 btn-add">
            <GrUpdate className="h-5 w-5" />
          </button>
        </div>
      </header>
      <div>
        <button
          onClick={toggleAddDefinitionModal}
          className="px-4 py-2 btn-add"
        >
          اضافة الدرس
        </button>
      </div>

      {showAddDefinitionModal && (
        <LessonModal
          lessonCategoryid={lessoncategoryId || ""}
          onClose={toggleAddDefinitionModal}
        />
      )}

      {isLoading ? <Loading /> : <LessonsTable lessons={Data?.content || []} />}

      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default LessonsListPage
