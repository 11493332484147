import LoginForm from "../../components/auth/LoginForm"
import Logo from "../../assets/images/Logo.svg"

const LoginPage = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img src={Logo} className="mx-auto h-22 w-auto" alt="Your Logo" />
        </div>
        <LoginForm />
      </div>
    </div>
  )
}

export default LoginPage
