import React, { useState } from "react"
import { FaTrashAlt } from "react-icons/fa"

interface DeleteButtonProps {
  onDelete: (e: any, itemId: string) => void | Promise<void>
  itemId: string
  itemName?: string
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onDelete,
  itemId,
  itemName = "item",
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      <button
        className="text-red-500 border border-red-500 hover:bg-red-500 hover:text-white transition-colors duration-150 px-2 py-1 rounded"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation()
          setIsDialogOpen(true)
        }}
      >
        <FaTrashAlt className="w-5 h-5" />
      </button>
      {isDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full text-right">
            <h2 className="font-semibold text-lg mb-4">تأكيد الحذف</h2>
            <p className="text-wrap">
              هل أنت متأكد أنك تريد حذف {itemName} هذا؟
            </p>
            <div className="flex justify-start mt-4 space-x-reverse space-x-2">
              <button
                className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded transition-colors duration-150"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                ) => {
                  e.stopPropagation()

                  setIsDialogOpen(false)
                }}
              >
                إلغاء
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors duration-150"
                onClick={(e) => {
                  onDelete(e, itemId)
                  setIsDialogOpen(false)
                }}
              >
                تأكيد
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DeleteButton
