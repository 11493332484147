import { useState, useEffect } from "react"
import { NavLink, useNavigate, useLocation } from "react-router-dom"
import Logo from "../../../assets/images/Logo.svg"
import {
  FaHome,
  FaBook,
  FaChalkboardTeacher,
  FaMosque,
  FaBroadcastTower,
  FaCog,
  FaSignOutAlt,
  FaGlobeAfrica,
  FaTv,
  FaNewspaper,
  FaBookOpen,
  FaUsersCog,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa"
import { IoNotifications } from "react-icons/io5"
import { BiSolidBookBookmark } from "react-icons/bi"
import { TbMailQuestion } from "react-icons/tb"
import {
  removeAccessToken,
  removeRefreshToken,
} from "../../../utils/auth-utilis"
import { MdQueueMusic } from "react-icons/md"

const Sidebar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [openSection, setOpenSection] = useState<string | null>(null)

  useEffect(() => {
    const path = location.pathname
    if (
      path.includes("/definitions") ||
      path.includes("/methods") ||
      path.includes("/sanad-zawitina")
    ) {
      setOpenSection("info")
    } else if (
      path.includes("/educational-lessons") ||
      path.includes("/friday-sermons") ||
      path.includes("/amdahs")
    ) {
      setOpenSection("lessons")
    } else if (
      path.includes("/scientific-programs") ||
      path.includes("/tours") ||
      path.includes("/live-broadcast")
    ) {
      setOpenSection("activities")
    } else if (
      path.includes("/user-management") ||
      path.includes("/notofications") ||
      path.includes("/settings") ||
      path.includes("/questions")
    ) {
      setOpenSection("misc")
    } else {
      setOpenSection("main")
    }
  }, [location.pathname])

  const toggleSection = (section: string) => {
    setOpenSection((prevSection) => (prevSection === section ? null : section))
  }

  const handleLogout = () => {
    removeRefreshToken()
    removeAccessToken()
    navigate("/login")
  }

  return (
    <div
      className="w-64 h-screen shadow-md flex flex-col"
      style={{
        background: "linear-gradient(180deg, #1E7B75 0%, #182A26 100%)",
      }}
    >
      <div className="p-5 text-white flex flex-col justify-between h-full">
        <div className="mb-4">
          <div className="flex justify-center mb-6">
            <img src={Logo} alt="Logo" className="h-12" />
          </div>
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer p-3 rounded-md hover:bg-green-800"
              onClick={() => toggleSection("main")}
            >
              <h3 className="text-gray-300 text-lg font-semibold">الرئيسية</h3>
              {openSection === "main" ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {openSection === "main" && (
              <div className="pl-4">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaHome className="w-5 h-5" />
                  <span>الرئيسية</span>
                </NavLink>
                <NavLink
                  to="/actuality"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaNewspaper className="w-5 h-5" />
                  <span>مستجدات</span>
                </NavLink>
              </div>
            )}
          </div>
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer p-3 rounded-md hover:bg-green-800"
              onClick={() => toggleSection("info")}
            >
              <h3 className="text-gray-300 text-lg font-semibold">المعلومات</h3>
              {openSection === "info" ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {openSection === "info" && (
              <div className="pl-4">
                <NavLink
                  to="/definitions"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaBook className="w-5 h-5" />
                  <span>التعريف بزاويتنا</span>
                </NavLink>
                <NavLink
                  to="/methods"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaBookOpen className="w-5 h-5" />
                  <span>أورادنا وأذكارنا</span>
                </NavLink>
                {/* <NavLink
                  to="/sanad-zawitina"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <BiSolidBookBookmark className="w-5 h-5" />
                  <span>سند زاويتنا و شيوخنا</span>
                </NavLink> */}
              </div>
            )}
          </div>
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer p-3 rounded-md hover:bg-green-800"
              onClick={() => toggleSection("lessons")}
            >
              <h3 className="text-gray-300 text-lg font-semibold">
                الدروس والخطب
              </h3>
              {openSection === "lessons" ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {openSection === "lessons" && (
              <div className="pl-4">
                <NavLink
                  to="/educational-lessons"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaChalkboardTeacher className="w-5 h-5" />
                  <span>دروس تربوية</span>
                </NavLink>
                <NavLink
                  to="/friday-sermons"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaMosque className="w-5 h-5" />
                  <span>خطب الجمعة</span>
                </NavLink>
                <NavLink
                  to="/amdahs"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <MdQueueMusic className="w-5 h-5" />
                  <span>سماعنا وإنشادنا</span>
                </NavLink>
              </div>
            )}
          </div>
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer p-3 rounded-md hover:bg-green-800"
              onClick={() => toggleSection("activities")}
            >
              <h3 className="text-gray-300 text-lg font-semibold">الأنشطة</h3>
              {openSection === "activities" ? (
                <FaChevronUp />
              ) : (
                <FaChevronDown />
              )}
            </div>
            {openSection === "activities" && (
              <div className="pl-4">
                <NavLink
                  to="/tours"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaGlobeAfrica className="w-5 h-5" />
                  <span>أنشطتنا و سياحاتنا</span>
                </NavLink>
                <NavLink
                  to="/scientific-programs"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaTv className="w-5 h-5" />
                  <span>برامج إعلامية</span>
                </NavLink>
                <NavLink
                  to="/live-broadcast"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaBroadcastTower className="w-5 h-5" />
                  <span>البث المباشر</span>
                </NavLink>
              </div>
            )}
          </div>
          <div className="mb-4">
            <div
              className="flex items-center justify-between cursor-pointer p-3 rounded-md hover:bg-green-800"
              onClick={() => toggleSection("misc")}
            >
              <h3 className="text-gray-300 text-lg font-semibold">متفرقات</h3>
              {openSection === "misc" ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {openSection === "misc" && (
              <div className="pl-4">
                <NavLink
                  to="/user-management"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaUsersCog className="w-5 h-5" />
                  <span>المستخدمين</span>
                </NavLink>
                <NavLink
                  to="/notofications"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <IoNotifications className="w-5 h-5" />
                  <span>الإشعارات</span>
                </NavLink>
                <NavLink
                  to="/questions"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <TbMailQuestion className="w-5 h-5" />
                  <span>أسئلتكم</span>
                </NavLink>
                <NavLink
                  to="/settings"
                  className={({ isActive }) =>
                    `flex items-center gap-2 py-2 px-3 mt-2 transition-colors duration-300 rounded-md ${
                      isActive
                        ? "text-green-300 bg-green-900 bg-opacity-20"
                        : "text-gray-300 hover:bg-green-800 hover:bg-opacity-50"
                    }`
                  }
                >
                  <FaCog className="w-5 h-5" />
                  <span>الإعدادات</span>
                </NavLink>
              </div>
            )}
          </div>
        </div>

        <div className="mt-4">
          <button
            onClick={handleLogout}
            className="flex items-center gap-4 py-2 px-3 transition-colors duration-300 rounded-md text-gray-300 hover:bg-blue-700 w-full text-right"
          >
            <FaSignOutAlt className="w-5 h-5" /> <span>تسجيل الخروج</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
