import classNames from "classnames"
import { useRef, useContext, useState } from "react"
import { useForm, Controller, FieldErrors } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { UserContext } from "../../context/AuthContext"
import { useMutation } from "@tanstack/react-query"
import { getUserInfo, signIn } from "../../services/auth"
import { setAccessToken, setRefreshToken } from "../../utils/auth-utilis"

interface PinCodeInputProps {
  name: string
  length: number
  numberPhone: string
}

interface FormData {
  [key: string]: string[]
}

const PinCodeInput: React.FC<PinCodeInputProps> = ({
  name,
  length,
  numberPhone,
}) => {
  const [error, setError] = useState<any | null>(null)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()
  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const navigate = useNavigate()

  const { setUser, setAuthenticated } = useContext(UserContext)

  const { mutate: login, isPending: isLoading } = useMutation({
    mutationFn: signIn,
    onSuccess: (res) => {
      if (res.status === 200) {
        setAccessToken(res.data.access_token)
        setRefreshToken(res.data.refresh_token)
        getUserInfo()
          .then((res) => {
            setUser(res)
            setAuthenticated(true)
            navigate("/")
          })
          .catch(() => {
            setUser(null)
            setAuthenticated(false)
          })
      }
    },
    onError: (error) => {
      if (error && typeof error === "object" && "response" in error) {
        const messageError = error as {
          response: { data: { message?: string } }
        }
        setError(messageError.response.data.message)
      } else {
        setError("يرجى التحقق من رمز التحقق")
      }
    },
  })

  const onSubmit = (data: FormData) => {
    const pinCodeValues = data[name]
    const pinCode = pinCodeValues.join("")
    login({ code: pinCode, phoneNumber: numberPhone })
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target
    const re = /^[0-9]*$/

    if (re.test(value)) {
      if (value.length === 1) {
        inputRefs.current[index + 1]?.focus()
      } else if (value.length > 1) {
        value.split("").forEach((digit, idx) => {
          if (idx < length) {
            const input = inputRefs.current[index + idx]
            if (input) {
              input.value = digit
            }
          }
        })
        inputRefs.current[Math.min(index + value.length, length - 1)]?.focus()
      } else if (index > 0) {
        inputRefs.current[index - 1]?.focus()
      }
    } else {
      e.preventDefault()
    }
  }

  const handlePaste = (
    e: React.ClipboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    const paste = e.clipboardData.getData("text")
    const re = /^[0-9]+$/
    if (re.test(paste)) {
      paste.split("").forEach((digit, idx) => {
        if (idx + index < length) {
          const input = inputRefs.current[idx + index]
          if (input) {
            input.value = digit
          }
        }
      })
      inputRefs.current[Math.min(index + paste.length, length - 1)]?.focus()
    }
    e.preventDefault()
  }

  const hasError = (index: number) => {
    const fieldError = errors[name] as FieldErrors | undefined
    return fieldError && Array.isArray(fieldError) && !!fieldError[index]
  }

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div>
          <p className="title">التحقق من رقم الهاتف</p>
          <p className="mt-4">
            الرجاء إدخال رمز التحقق المرسل إلى{" "}
            <span className="font-bold" dir="ltr">
              {numberPhone}
            </span>
          </p>
        </div>
        <div className="flex flex-col space-y-2 my-8">
          <p className="text-gray-600 text-sm">رمز التحقق</p>
          <div className="flex justify-between items-center" dir="ltr">
            {Array.from({ length }).map((_, index) => (
              <Controller
                key={index}
                name={`${name}.${index}`}
                control={control}
                rules={{ required: true, pattern: /^[0-9]*$/ }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="text"
                    maxLength={1}
                    className={classNames(
                      "w-12 h-12 text-center text-xl font-bold border-2 rounded-lg focus:outline-none focus:border-blue-500",
                      {
                        "border-red-500": hasError(index),
                      },
                    )}
                    onChange={(e) => {
                      field.onChange(e)
                      handleInputChange(e, index)
                    }}
                    onPaste={(e) => handlePaste(e, index)}
                    ref={(el) => {
                      field.ref(el)
                      inputRefs.current[index] = el
                    }}
                  />
                )}
              />
            ))}
          </div>
          {error && <p className="text-xs text-red-500">{error}</p>}
          <p className="text-sm">
            {" "}
            لم تحصل على رمز؟
            <span className="font-bold cursor-pointer">أعد إرسال الرمز</span>
          </p>
        </div>
        <button
          type="submit"
          className="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-green-600 to-green-900 hover:bg-green-800"
        >
          {isLoading ? "جاري التحميل..." : "تسجيل الدخول"}
        </button>
      </form>
    </div>
  )
}

export default PinCodeInput
