import { useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { sendCode } from "../../services/auth"
import { useMutation } from "@tanstack/react-query"
import classNames from "classnames"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import PinCode from "./PinCode"
import { validatePhoneNumber } from "../../utils/formValidation"
import { queryClient } from "../../utils/reactQueryClient"

interface PhoneFormInput {
  phoneNumber: string
}

const LoginForm: React.FC<{ errorMessage?: string }> = ({ errorMessage }) => {
  const [validePhone, setValidePhone] = useState<boolean>(false)

  const {
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    control,
  } = useForm<PhoneFormInput>()

  const { mutate: operatCode, isPending: isLoading } = useMutation({
    mutationFn: sendCode,
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ["user"] })
        setValidePhone(true)
      }
    },
    onError: (error) => {
      setError("phoneNumber", {
        type: "invalid",
        message: "يرجى التحقق من رقم الهاتف",
      })
    },
  })

  const onSubmit = (data: PhoneFormInput) => {
    operatCode(data)
  }

  if (validePhone)
    return (
      <PinCode length={6} name="phone" numberPhone={watch("phoneNumber")} />
    )

  return (
    <div>
      <h2 className="mt-6 text-center title">تسجيل الدخول إلى حسابك</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="my-7">
          <label className="block text-sm font-medium text-gray-700">
            رقم الهاتف
          </label>
          <div
            dir="ltr"
            className={classNames(
              "border mt-2 border-gray-300 w-full rounded-xl py-2 bg-white pl-6 font-bold shadow-sm",
              { "border-red-500": errors.phoneNumber },
            )}
          >
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: "رقم الهاتف مطلوب",
                validate: validatePhoneNumber,
              }}
              render={({ field: { onChange, value } }) => (
                <PhoneInput
                  country="ma"
                  value={value ? `+${value}` : "+"}
                  onChange={(phone) =>
                    onChange(phone.startsWith("+") ? phone : `+${phone}`)
                  }
                  inputStyle={{
                    border: "none",
                    borderRadius: "0.75rem",
                    width: "100%",
                  }}
                  containerStyle={{
                    border: "none",
                    width: "100%",
                  }}
                  buttonStyle={{
                    border: "none",
                    backgroundColor: "white",
                    padding: "0px",
                  }}
                />
              )}
            />
          </div>
          {errors.phoneNumber && (
            <p className="text-red-500 text-xs">{errors.phoneNumber.message}</p>
          )}
        </div>
        {errorMessage && <p className="text-red-500 text-xs">{errorMessage}</p>}
        <div>
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-green-600 to-green-900 hover:bg-green-800"
          >
            {isLoading ? "جاري الإرسال..." : "إرسال رمز التحقق"}
          </button>
        </div>
      </form>
    </div>
  )
}

export default LoginForm
