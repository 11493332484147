import { useState } from "react"
import Calendar, { CalendarProps } from "react-calendar"
import "react-calendar/dist/Calendar.css"
import { LuCalendarRange } from "react-icons/lu"
import { ParseTime } from "../../utils/parseDate"

interface DatePickerProps {
  date: string | null
  setDate: (date: string | null) => void
}

const DatePicker: React.FC<DatePickerProps> = ({ date, setDate }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const formattedDate = date ? ParseTime(date) : ""

  const toggleCalendar = () => {
    setIsCalendarOpen((prev) => !prev)
  }

  const handleCalendarChange: CalendarProps["onChange"] = (value, event) => {
    if (value instanceof Date) {
      setDate(value.toISOString())
    } else if (Array.isArray(value) && value.length === 2) {
      setDate(value[0]?.toISOString() ?? null)
    } else {
      setDate(null)
    }
  }

  return (
    <div className="relative w-full">
      <div className="w-full relative md:w-auto">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <LuCalendarRange className="w-7 h-7 pl-2 text-gray-600" />
        </div>
        <input
          name="date"
          className="input pr-10"
          placeholder="اختر تاريخ"
          value={formattedDate}
          onClick={toggleCalendar}
          readOnly
        />
      </div>

      {isCalendarOpen && (
        <div className="absolute z-10 mt-2 p-4 bg-white shadow-lg rounded-lg">
          <Calendar
            locale="ar"
            onChange={handleCalendarChange}
            value={date ? new Date(date) : null}
          />
          <button
            onClick={toggleCalendar}
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors duration-200 w-full"
          >
            إخفاء التقويم
          </button>
        </div>
      )}
    </div>
  )
}

export default DatePicker
