import { QueryClientProvider } from "@tanstack/react-query"
import AppRoutes from "./routes/AppRoutes"
import { queryClient } from "./utils/reactQueryClient"
import { UserProvider } from "./context/AuthContext"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </QueryClientProvider>
  )
}

export default App
