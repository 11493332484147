import { useState } from "react"
import apiClient from "../../utils/api"
import classNames from "classnames"
import ImageModal from "../tours/modal/ImageModal"
import ConfirmDelete from "../tours/modal/ConfirmDelete"
import {
  checkExtension,
  filterByExtensions,
  isValidUrl,
} from "../../utils/functions"
import { FaPlay } from "react-icons/fa6"
import Player from "./Player"

interface FileUploadProps {
  onFileUpload: (fileNames: string[]) => void
  ImgUrls: any[]
  type: string[]
  error: any
}

interface FileUploadResponse {
  fileName: string
  fileType: string
}

const MultiFileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  ImgUrls,
  error,
  type,
}) => {
  const [uploading, setUploading] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null)
  const [pictureUrls, setPictureUrls] = useState(ImgUrls)
  const [showImage, setShowImage] = useState<string | null>(null)
  const [showVideo, setShowVideo] = useState<string | null>(null)
  const [errorFile, setErrorFile] = useState<string | null>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const selectedFileNames = Array.from(files).map((file) => file.name)
      const isValidType = selectedFileNames.every((fileName) =>
        type.some((allowedType) => fileName.endsWith(allowedType)),
      )
      if (isValidType) {
        setSelectedFiles(files)
        setErrorFile(null)
      } else {
        setSelectedFiles(null)
        setErrorFile(`نوع الملف غير صالح. الرجاء تحميل ملف  ${type.join(", ")}`)
      }
    }
  }

  const uploadFiles = async () => {
    if (!selectedFiles) return
    setUploading(true)
    try {
      const fileUploadResponses: FileUploadResponse[] = []

      for (let i = 0; i < selectedFiles.length; i++) {
        const formData = new FormData()
        formData.append("file", selectedFiles[i])

        const response = await apiClient.post("/storage/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        const fileUploadResponse: FileUploadResponse = {
          fileName: response.data.fileName,
          fileType: response.data.fileType,
        }
        fileUploadResponses.push(fileUploadResponse)
      }

      const fileNames = fileUploadResponses.map((response) => response.fileName)
      setPictureUrls([...pictureUrls, ...fileNames])
      onFileUpload([...pictureUrls, ...fileNames])
    } catch (error) {
      console.error("Error uploading files:", error)
    } finally {
      setUploading(false)
      setSelectedFiles(null)
    }
  }

  const deleteUrl = (urlImg: string) => {
    const updatedUrls = pictureUrls.filter((element) => element !== urlImg)
    setPictureUrls(updatedUrls)
    onFileUpload(updatedUrls)
  }

  return (
    <div className={classNames("input", { "input-invalid": error })}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <label className="cursor-pointer bg-gradient-to-r from-green-600 to-green-900 hover:bg-green-800 text-white py-1 px-4 rounded-lg shadow-lg">
            <span>اختر الملف</span>
            <input
              type="file"
              accept={type.join(",")}
              onChange={handleFileChange}
              multiple
              disabled={uploading}
              className="hidden"
            />
          </label>
          <div>
            {errorFile && (
              <p className="text-red-600 text-sm pr-11">{errorFile}</p>
            )}
            {uploading && (
              <p className="text-green-600 pr-11">جاري التحميل ...</p>
            )}
          </div>
        </div>

        {selectedFiles && (
          <button
            onClick={uploadFiles}
            className="ml-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
            disabled={uploading}
          >
            تحميل
          </button>
        )}
      </div>

      <div className="flex flex-col space-y-3 py-3 max-h-32 overflow-y-scroll custom-scrollbar">
        {filterByExtensions(pictureUrls, type)?.length === 0 ? (
          <div className="flex justify-between items-center bg-blue-200 bg-opacity-50 shadow-md rounded-xl p-2 hover:bg-opacity-100 transition-colors duration-300">
            <p className="text-base text-blue-900">لم يتم رفع أي ملف</p>
          </div>
        ) : (
          filterByExtensions(pictureUrls, type)?.map((url, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-blue-200 bg-opacity-50 shadow-md rounded-xl p-2 hover:bg-opacity-100 transition-colors duration-300"
            >
              <div className="flex justify-between items-center">
                <p className="text-base text-blue-900 text-wrap">
                  {url?.length > 20 ? url.substring(0, 20) + "..." : url}
                </p>
                {checkExtension(url) === "mp4" ? (
                  <FaPlay
                    onClick={() => setShowVideo(url)}
                    className="w-8 h-8  text-blue-500 p-1 hover:scale-150 transition-all duration-700mr-4 cursor-pointer"
                  />
                ) : (
                  <img
                    onClick={() => setShowImage(url)}
                    src={
                      isValidUrl(url)
                        ? url
                        : `https://islamic.peaqock.com/api/storage/downloadFile/${url}`
                    }
                    className="w-8 h-8  text-blue-500 p-1 hover:scale-150 transition-all duration-700mr-4 cursor-pointer"
                    alt="picture trip"
                  />
                )}
              </div>

              <ConfirmDelete onDelete={() => deleteUrl(url)} url={url} />

              {showImage && (
                <ImageModal
                  onClose={() => setShowImage(null)}
                  url={showImage}
                />
              )}
              {showVideo && (
                <Player onClose={() => setShowVideo(null)} url={showVideo} />
              )}
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default MultiFileUpload
