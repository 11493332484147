import { MdEdit } from "react-icons/md"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import DeleteButton from "../../shared/DeleteButton"
import { useContext, useState } from "react"
import { ProgramData } from "../../../types/programsTypes"
import ProgramModal from "../modal/ProgramModal"
import { deleteProgram } from "../../../services/programs"
import { isValidUrl, limitContent } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"

interface ProgramsTableProps {
  programs: ProgramData[]
}

const ProgramsTable: React.FC<ProgramsTableProps> = ({ programs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingProgram, setEditingProgram] = useState<ProgramData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeProgram } = useCustomMutation({
    mutationFn: deleteProgram,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["programsByCategories"] })
    },
  })

  const handleEdit = (program: ProgramData) => {
    setEditingProgram(program)
    setIsModalOpen(true)
  }

  const handleDelete = (programId: string) => {
    removeProgram(programId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الواجهة
            </th>
            <th scope="col" className="th-text max-w-[10rem] text-wrap">
              العنوان
            </th>
            <th scope="col" className="th-text">
              تاريخ الإنشاء
            </th>
            <th scope="col" className="th-text w-[40%]">
              المحتوى
            </th>
            <th scope="col" className="th-text text-left pl-12">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {programs?.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={4}>
                لا توجد بيانات{" "}
              </td>
            </tr>
          ) : (
            programs.map((program) => (
              <tr key={program.id} className="hover:bg-gray-50">
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(program?.coverImgUrl)
                        ? program?.coverImgUrl
                        : `https://islamic.peaqock.com/api/storage/downloadFile/${program?.coverImgUrl}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="Cover Img"
                  />
                </td>
                <td className="td-text text-wrap text-xs">{program.title}</td>
                <td className="td-text">
                  {new Date(program.creationDate).toLocaleDateString("fr-EG")}
                </td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[40%]">
                  {limitContent(program.description || "-")}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(program)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5" />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(program.id)}
                      itemId={program.id}
                      itemName={program.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <ProgramModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingProgram(undefined)
          }}
          programs={editingProgram}
        />
      )}
    </div>
  )
}

export default ProgramsTable
