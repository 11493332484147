import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import { LessonsData } from "../../../types/lessonsTypes"
import { addLesson, editLesson } from "../../../services/lessons"
import classNames from "classnames"
import UploadImage from "../../shared/UploadImage"
import UploadFile from "../../shared/UploadFile"
import { lessonsType } from "../../../constants"
import { youtubeUrlPattern } from "../../../utils/formValidation"
import DatePicker from "../../shared/DatePicker"

interface LessonModalProps {
  onClose: () => void
  lesson?: LessonsData | null
  lessonCategoryid?: string
}
interface FileUploadResponse {
  fileName: string
  fileType: string
}
type ValidProperty =
  | "id"
  | "title"
  | "authorName"
  | "imgCover"
  | "lessonsType"
  | "lessonUrl"
  | "creationDate"
  | "updatedDate"

const LessonModal: React.FC<LessonModalProps> = ({
  onClose,
  lesson,
  lessonCategoryid,
}) => {
  const [contentUrl, setContentUrl] = useState<string | null>(
    lesson?.lessonUrl || null,
  )
  const [fileType, setFileType] = useState<string | null>(
    lesson?.lessonsType || null,
  )

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
    setError,
  } = useForm<LessonsData>()

  const { mutate: operateLesson } = useCustomMutation({
    mutationFn: lesson ? editLesson : addLesson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["lessons"] })

      onClose()
    },
  })

  useEffect(() => {
    if (lesson) {
      reset(lesson)
    }
  }, [lesson, reset])

  const onSubmit = (data: LessonsData) => {
    operateLesson({
      ...data,
      lessonsCategory_id: lessonCategoryid || "",
    })
  }

  const handleFileUpload = (
    fileUrl: FileUploadResponse,
    fileProperty: ValidProperty,
  ) => {
    setValue(fileProperty, fileUrl.fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("imgCover", { required: "رابط صورة الواجهة مطلوب" })
    register("lessonUrl", { required: "رابط  محتوى الدرس مطلوب" })
    register("dateLesson", { required: "تاريخ الدرس مطلوب" })
  }, [register])

  useEffect(() => {
    console.log(fileType)
  }, [watch("lessonsType")])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">{lesson ? " تعديل درس " : "إضافة درس جديد "} </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700 "
            >
              عنوان الدرس
            </label>
            <input
              {...register("title", { required: "هذا الحقل مطلوب" })}
              id="title"
              className={classNames("input", { "input-invalid": errors.title })}
              placeholder="عنوان الدرس"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title.message}</p>
            )}
          </div>
          <div className="mb-4 flex justify-between">
            <div className="w-[49%]">
              <label className="block text-sm font-medium text-gray-700">
                تاريخ الدرس
              </label>
              <DatePicker
                date={watch("dateLesson")}
                setDate={(val) =>
                  setValue("dateLesson", val, { shouldValidate: true })
                }
              />
              {errors.dateLesson && (
                <p className="text-red-500 text-xs">
                  {errors.dateLesson.message}
                </p>
              )}
            </div>
            <div className="w-[49%]">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700 "
              >
                البرنامج
              </label>
              <input
                {...register("program", { required: "هذا الحقل مطلوب" })}
                id="program"
                className={classNames("input", {
                  "input-invalid": errors.program,
                })}
                placeholder="عنوان الدرس"
              />
              {errors.program && (
                <p className="text-red-500 text-xs">{errors.program.message}</p>
              )}
            </div>
          </div>

          <div className="mb-4">
            <label
              htmlFor="authorName"
              className="block text-sm font-medium text-gray-700"
            >
              تحميل الواجهة
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileName) =>
                handleFileUpload(fileName, "imgCover")
              }
              ImgUrl={lesson?.imgCover || null}
              error={errors.imgCover}
              setError={(err) =>
                setError("imgCover", { type: "invalid", message: err })
              }
            />
            {errors.imgCover && (
              <p className="text-red-500 text-xs">{errors.imgCover.message}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              نوع محتوى الدرس
            </label>
            <select
              {...register("lessonsType", { required: "هذا الحقل مطلوب" })}
              className={classNames("input", { "input-invalid": errors.type })}
              defaultValue={"VIDEO"}
              onChange={(e) => {
                setContentUrl(null)
                setValue("lessonUrl", null)
                setFileType(e.target.value)
              }}
            >
              {lessonsType?.map((type, index) => (
                <option key={index} value={type.value}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              تحميل محتوى الدرس
            </label>
            <>
              {fileType === "AUDIO" ? (
                <>
                  <UploadFile
                    onFileUpload={(fileName) =>
                      handleFileUpload(fileName, "lessonUrl")
                    }
                    fileType={fileType}
                    fileUrl={contentUrl}
                    error={errors.lessonUrl}
                  />
                </>
              ) : (
                <input
                  {...register("lessonUrl", {
                    required: "رابط يوتيوب مطلوب",
                    pattern: youtubeUrlPattern,
                  })}
                  className={classNames("input", {
                    "input-invalid": errors.lessonUrl,
                  })}
                  placeholder="رابط يوتيوب"
                />
              )}
              {errors.lessonUrl && (
                <p className="text-red-500 text-xs">
                  {errors.lessonUrl.message}
                </p>
              )}
            </>
          </div>

          <div className="flex justify-between pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LessonModal
