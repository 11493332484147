import apiClient from "../utils/api"
import { ListSortResponseData } from "../types/sortTypes"

export const getListSort = async (): Promise<ListSortResponseData> => {
  const response = await apiClient.get(`/list-sort`)
  return response.data
}

export const updateSortList = (listData: ListSortResponseData) => {
  return apiClient.post("list-sort/update-items", listData)
}
