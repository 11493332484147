import { Controller, useForm } from "react-hook-form"
import { UserModalProps, UsersData } from "../../../types/userManagementTypes"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import { useEffect } from "react"
import classNames from "classnames"
import { validatePhoneNumber } from "../../../utils/formValidation"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { roleUser } from "../../../constants"
import { addUser, editUser } from "../../../services/usersMangement"

const UserManagementModal: React.FC<UserModalProps> = ({ onClose, user }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<UsersData>()

  const { mutate: operateDefinition } = useCustomMutation({
    mutationFn: user ? editUser : addUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
      onClose()
    },
  })

  const onSubmit = (data: any) => {
    operateDefinition({ roleEntity: watch("role"), ...data })
  }

  useEffect(() => {
    if (user) {
      reset(user)
    }
  }, [user, reset])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">{user ? " تعديل  " : "إضافة  "}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              الإسم
            </label>
            <input
              {...register("firstname", { required: "هذا الحقل مطلوب" })}
              className={classNames("input", {
                "input-invalid": errors.firstname,
              })}
              placeholder="الإسم"
            />
            {errors.firstname && (
              <p className="text-red-500 text-xs">{errors.firstname.message}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              النسب
            </label>
            <input
              {...register("lastname", { required: "هذا الحقل مطلوب" })}
              className={classNames("input", {
                "input-invalid": errors.lastname,
              })}
              placeholder="النسب"
            />
            {errors.lastname && (
              <p className="text-red-500 text-xs">{errors.lastname.message}</p>
            )}
          </div>

          <div className="my-7">
            <label className="block text-sm font-medium text-gray-700">
              رقم الهاتف
            </label>
            <div
              dir="ltr"
              className={classNames(
                "border mt-2 border-gray-300 w-full rounded-xl py-1 bg-white pl-6 font-bold shadow-sm",
                { "border-red-500": errors.phoneNumber },
              )}
            >
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: "رقم الهاتف مطلوب",
                  validate: validatePhoneNumber,
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    country="ma"
                    value={value ? `+${value}` : "+"}
                    onChange={(phone) =>
                      onChange(phone.startsWith("+") ? phone : `+${phone}`)
                    }
                    inputStyle={{
                      border: "none",
                      borderRadius: "0.75rem",
                      width: "100%",
                    }}
                    containerStyle={{
                      border: "none",
                      width: "100%",
                    }}
                    buttonStyle={{
                      border: "none",
                      backgroundColor: "white",
                      padding: "0px",
                    }}
                  />
                )}
              />
            </div>
            {errors.phoneNumber && (
              <p className="text-red-500 text-xs">
                {errors.phoneNumber.message}
              </p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              دور المستخدم
            </label>
            <select
              {...register("role", { required: "هذا الحقل مطلوب" })}
              className={classNames("input", { "input-invalid": errors.role })}
              defaultValue={"ADMIN"}
            >
              {roleUser?.map((role, index) => (
                <option key={index} value={role.value}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-between pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserManagementModal
