import apiClient from "../utils/api"
import {
  LessonCategoryData,
  LessonsCategoriesResponse,
  LessonsData,
  LessonsResponse,
} from "../types/lessonsTypes"

export const getAllLessons = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
  type: string,
  fromDate: string,
  toDate: string,
): Promise<LessonsResponse> => {
  const response = await apiClient.get(
    `/lessons/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}&lessonsType=${type}&fromDate=${fromDate}&toDate=${toDate}`,
  )
  return response.data
}

export const addLesson = (lessonsData: LessonsData) => {
  return apiClient.post("/lessonsEduc", lessonsData)
}

export const editLesson = (lessonsData: LessonsData) => {
  return apiClient.put(`/lessonsEduc/${lessonsData.id}`, lessonsData)
}
export const deleteLesson = (lessonId: string) => {
  return apiClient.delete(`/lessonsEduc/${lessonId}`)
}

export const getAllLessonCategories = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<LessonsCategoriesResponse> => {
  const response = await apiClient.get(
    `/lessonsCategories/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addLessonCategory = (lessonCategoryData: LessonCategoryData) => {
  return apiClient.post("/lessonsCategories", lessonCategoryData)
}

export const editLessonCategory = (lessonCategoryData: LessonCategoryData) => {
  return apiClient.put(
    `/lessonsCategories/${lessonCategoryData.id}`,
    lessonCategoryData,
  )
}

export const deleteLessonCategory = (lessonCategoryId: string) => {
  return apiClient.delete(`/lessonsCategories/${lessonCategoryId}`)
}

export const getLessonsByCategoryId = async (
  lessonCategoryId: string,
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
  type: string,
  fromDate: string,
  toDate: string,
): Promise<LessonsResponse> => {
  const response = await apiClient.get(
    `/lessonsEduc/search?lessonsEducId=${lessonCategoryId}&pageNo=${pageNo}&pageSize=${pageSize}&key=${search}&lessonsType=${type}&fromDate=${fromDate}&toDate=${toDate}`,
  )
  return response.data
}
