import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { queryClient } from "../../../utils/reactQueryClient"
import useCustomMutation from "../../../hooks/useCustomMutation"

import classNames from "classnames"
import UploadImage from "../../shared/UploadImage"
import { LessonCategoryData } from "../../../types/lessonsTypes"
import {
  addLessonCategory,
  editLessonCategory,
} from "../../../services/lessons"

interface LessonCategoryModalProps {
  onClose: () => void
  lessonCategory?: LessonCategoryData | null
}

const LessonCategoryModal: React.FC<LessonCategoryModalProps> = ({
  onClose,
  lessonCategory,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
    setError,
  } = useForm<LessonCategoryData>()

  const { mutate: operateLessonCategory } = useCustomMutation({
    mutationFn: lessonCategory ? editLessonCategory : addLessonCategory,
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ["lessonCategories"] })
        onClose()
      }
    },
  })

  useEffect(() => {
    if (lessonCategory) {
      reset(lessonCategory)
    }
  }, [lessonCategory, reset])

  const onSubmit = async (data: LessonCategoryData) => {
    operateLessonCategory(data)
  }

  const handleFileUpload = (fileName: string) => {
    setValue("coverImgUrl", fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("coverImgUrl", { required: "رابط صورة الغلاف" })
  }, [register])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white p-8 rounded-md w-full max-w-md ">
        <h2 className="h2">
          {lessonCategory ? " تعديل تصنيف " : "إضافة تصنيف جديد "}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              العنوان
            </label>
            <input
              {...register("title", { required: "العنوان مطلوب" })}
              id="title"
              className={classNames("input", { "input-invalid": errors.title })}
              placeholder="العنوان"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title.message}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="authorName"
              className="block text-sm font-medium text-gray-700"
            >
              تحميل الصورة
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileUrl) => handleFileUpload(fileUrl.fileName)}
              ImgUrl={lessonCategory?.coverImgUrl || null}
              error={errors.coverImgUrl}
              setError={(err) =>
                setError("coverImgUrl", { type: "invalid", message: err })
              }
            />
            {errors.coverImgUrl && (
              <p className="text-red-500 text-xs">
                {errors.coverImgUrl.message}
              </p>
            )}
          </div>
          <div className="flex justify-between mt-4 pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default LessonCategoryModal
