import { useState } from "react"
import SearchInput from "../../components/shared/SearchInput"
import useDebounceState from "../../hooks/useDebounceState"
import { useQuery } from "@tanstack/react-query"
import { getAllNotifications } from "../../services/notifications"
import Loading from "../../components/shared/Loading"
import NotificationsTable from "../../components/notification/table/NotificationsTable"
import NotificationModal from "../../components/notification/modal/NotificationModal"
import Pagination from "../../components/shared/Pagination"

const NotificationsListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddNotificationsModal, setShowAddNotificationsModal] =
    useState(false)
  const pageSize = 5

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: Data,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["notifications", currentPage, search],
    queryFn: () => getAllNotifications(currentPage, pageSize, search),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const toggleAddNotificationsModal = () =>
    setShowAddNotificationsModal(!showAddNotificationsModal)
  const totalCount = Data?.totalElements ?? 0

  if (isError) {
    return <div>Error fetching </div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-8">
        <h1 className="title">الإشعارات</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button
            onClick={toggleAddNotificationsModal}
            className="px-4 py-2 btn-add"
          >
            اضافة إشعار{" "}
          </button>
        </div>
      </header>

      {isLoading ? (
        <Loading />
      ) : (
        <NotificationsTable notifications={Data?.content || []} />
      )}
      {showAddNotificationsModal && (
        <NotificationModal onClose={toggleAddNotificationsModal} />
      )}

      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default NotificationsListPage
