import apiClient from "../utils/api"
import { SermonsData, SermonsResponse } from "../types/sermonsTypes"

export const getAllSermons = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<SermonsResponse> => {
  const response = await apiClient.get(
    `/sermonFriday/search?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addSermon = (sermonsData: SermonsData) => {
  return apiClient.post("/sermonFriday", sermonsData)
}

export const editSermon = (sermonsData: SermonsData) => {
  return apiClient.put(`/sermonFriday/${sermonsData.id}`, sermonsData)
}
export const deleteSermon = (sermonId: string) => {
  return apiClient.delete(`/sermonFriday/${sermonId}`)
}
