import React, { useState } from "react"
import { FaTrashAlt } from "react-icons/fa"

interface ConfirmDeleteProps {
  onDelete: () => void
  url: string
}

const ConfirmDelete: React.FC<ConfirmDeleteProps> = ({ onDelete, url }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  return (
    <>
      <div
        className="cursor-pointer text-red-500 border border-red-500 hover:bg-red-500 hover:text-white transition-colors duration-150 px-2 py-1 rounded"
        onClick={() => {
          setIsDialogOpen(true)
        }}
      >
        <FaTrashAlt className="w-5 h-5" />
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full text-right">
            <h2 className="font-semibold text-lg mb-4">تأكيد الحذف</h2>
            <p className="text-wrap">هل أنت متأكد أنك تريد حذف {url}</p>
            <div className="flex justify-start mt-4 space-x-reverse space-x-2">
              <button
                className="bg-gray-200 hover:bg-gray-300 text-gray-800 px-4 py-2 rounded transition-colors duration-150"
                onClick={() => {
                  setIsDialogOpen(false)
                }}
              >
                إلغاء
              </button>
              <button
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition-colors duration-150"
                onClick={() => {
                  onDelete()
                  setIsDialogOpen(false)
                }}
              >
                تأكيد
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ConfirmDelete
