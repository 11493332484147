import { useContext, useState } from "react"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import { isValidUrl } from "../../../utils/functions"
import { MdEdit } from "react-icons/md"
import DeleteButton from "../../shared/DeleteButton"
import { UserContext } from "../../../context/AuthContext"
import { AmdahsData, AmdahTableProps } from "../../../types/amdahTypes"
import { deleteAmdah } from "../../../services/amdah"
import AmdahModal from "../modal/AmdahModal"

const AmdahsTable: React.FC<AmdahTableProps> = ({ amdahs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingAmdah, setEditingAmdah] = useState<AmdahsData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeAmdah } = useCustomMutation({
    mutationFn: deleteAmdah,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["amdah"] })
    },
  })

  const handleEdit = (amdah: AmdahsData) => {
    setEditingAmdah(amdah)
    setIsModalOpen(true)
  }

  const handleDelete = (amdahId: string) => {
    removeAmdah(amdahId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الواجهة
            </th>
            <th scope="col" className="th-text">
              العنوان
            </th>
            <th scope="col" className="th-text">
              تاريخ الإنشاء
            </th>
            <th scope="col" className="th-text text-center">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {amdahs.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={5}>
                لا توجد بيانات{""}
              </td>
            </tr>
          ) : (
            amdahs.map((amdah) => (
              <tr key={amdah.id} className="hover:bg-gray-50">
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(amdah.imgCover)
                        ? amdah.imgCover
                        : `https://islamic.peaqock.com/api/storage/downloadFile/${amdah?.imgCover}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="CoverImage"
                  />
                </td>
                <td className="td-text">{amdah.title}</td>
                <td className="td-text">
                  {new Date(amdah.creationDate).toLocaleDateString("fr-EG")}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(amdah)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5" />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(amdah.id)}
                      itemId={amdah.id}
                      itemName={amdah.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <AmdahModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingAmdah(undefined)
          }}
          amdah={editingAmdah}
        />
      )}
    </div>
  )
}

export default AmdahsTable
