import apiClient from "../utils/api"
import { LivesData, LivesResponse } from "../types/liveTypes"

export const getAllLives = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<LivesResponse> => {
  const response = await apiClient.get(
    `/facebookToken/all?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}&sortBy=creationDate&sortDir=desc`,
  )
  return response.data
}

export const addLive = (livesData: LivesData) => {
  return apiClient.post("/facebookToken/generate", livesData)
}

export const editLive = (livesData: LivesData) => {
  return apiClient.put(`/live/${livesData.id}`, livesData)
}

export const deleteLive = (liveId: string) => {
  return apiClient.delete(`/facebookToken/${liveId}`)
}
