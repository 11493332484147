import apiClient from "../utils/api"
import { ActualitysData, ActualitysResponse } from "../types/actualityTypes"

export const getAllActualitys = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<ActualitysResponse> => {
  const response = await apiClient.get(
    `/actuality/all?pageNo=${pageNo}&pageSize=${pageSize}&key=${search}`,
  )
  return response.data
}

export const addActuality = (actualitysData: ActualitysData) => {
  return apiClient.post("/actuality", actualitysData)
}

export const editActuality = (actualitysData: ActualitysData) => {
  return apiClient.put(`/actuality/${actualitysData.id}`, actualitysData)
}

export const deleteActuality = (actualityId: string) => {
  return apiClient.delete(`/actuality/${actualityId}`)
}
