import { useEffect } from "react"
import { useForm } from "react-hook-form"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import { ProgramData } from "../../../types/programsTypes"
import { addProgram, editProgram } from "../../../services/programs"
import classNames from "classnames"
import UploadImage from "../../shared/UploadImage"
import { youtubeUrlPattern } from "../../../utils/formValidation"
import MyFroalaEditor from "../../shared/MyFroalaEditor"

interface ProgramModalProps {
  onClose: () => void
  programs?: ProgramData | null
  programCategoryId?: string
}

const ProgramModal: React.FC<ProgramModalProps> = ({
  onClose,
  programs,
  programCategoryId,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setError,
    watch,
    formState: { errors },
  } = useForm<ProgramData>()

  const { mutate: operateProgram } = useCustomMutation({
    mutationFn: programs ? editProgram : addProgram,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["programsByCategories"] })
      onClose()
    },
  })

  useEffect(() => {
    if (programs) {
      reset(programs)
    }
  }, [programs, reset])

  const onSubmit = (data: ProgramData) => {
    operateProgram({
      ...data,
      programCategory_id: programCategoryId || "",
      type: "YOUTUBE",
    })
  }

  const handleImageUpload = (fileName: string) => {
    setValue("coverImgUrl", fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("coverImgUrl", { required: "رابط صورة الغلاف مطلوب" })
  }, [register])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2 pb-3">
          {programs ? " تعديل  برنامج " : "إضافة برنامج جديد "}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">
              العنوان
            </label>
            <input
              {...register("title", { required: "هذا الحقل مطلوب" })}
              className={classNames("input", { "input-invalid": errors.title })}
              placeholder="العنوان"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title.message}</p>
            )}
          </div>
          <div className="mb-2 flex items-center justify-between">
            <div className="w-[49%]">
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                تاريخ
              </label>
              <input
                {...register("dateProgram")}
                className={classNames("input", {
                  "input-invalid": errors.dateProgram,
                })}
                placeholder="تاريخ"
              />
              {errors.dateProgram && (
                <p className="text-red-500 text-xs">
                  {errors.dateProgram.message}
                </p>
              )}
            </div>
            <div className="w-[49%]">
              <label className="block text-sm font-medium text-gray-700">
                ترتيب الحلقة
              </label>
              <input
                type="number"
                {...register("programOrder", { required: "هذا الحقل مطلوب" })}
                className={classNames("input", {
                  "input-invalid": errors.programOrder,
                })}
                placeholder="ترتيب"
              />
              {errors.programOrder && (
                <p className="text-red-500 text-xs">
                  {errors.programOrder.message}
                </p>
              )}
            </div>
          </div>
          <div className="mb-2">
            <label className="block text-sm font-medium text-gray-700">
              تحميل الصورة
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileUrl) => handleImageUpload(fileUrl.fileName)}
              ImgUrl={programs?.coverImgUrl || null}
              error={errors.coverImgUrl}
              setError={(err) =>
                setError("coverImgUrl", { type: "invalid", message: err })
              }
            />
            {errors.coverImgUrl && (
              <p className="text-red-500 text-xs">
                {errors.coverImgUrl.message}
              </p>
            )}
          </div>
          <div className="">
            <label className="block text-sm font-medium text-gray-700">
              رابط فيديو يوتيوب
            </label>
            <input
              {...register("contentProgramUrl", {
                required: "رابط يوتيوب مطلوب",
                pattern: youtubeUrlPattern,
              })}
              className={classNames("input", {
                "input-invalid": errors.contentProgramUrl,
              })}
              placeholder="رابط يوتيوب"
            />
            {errors.contentProgramUrl && (
              <p className="text-red-500 text-xs">
                {errors.contentProgramUrl.message}
              </p>
            )}
          </div>
          <div className="mt-2">
            <label
              htmlFor=""
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {" "}
              المحتوى
            </label>
            <div className="max-w-full overflow-hidden">
              <MyFroalaEditor
                value={watch("description")}
                onChange={(val) => setValue("description", val)}
                error={errors.description}
              />
            </div>
            {errors.description && (
              <p className="text-red-500 text-xs">
                {errors.description.message}
              </p>
            )}
          </div>
          <div className="flex justify-between pt-2">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ProgramModal
