import { useState } from "react"
import { Questions, QuestionsTableProps } from "../../../types/questionTypes"
import { limitContent } from "../../../utils/functions"
import QuestionModal from "../modal/QuestionModal"
import { BiSolidShow } from "react-icons/bi"

const QuestionTable: React.FC<QuestionsTableProps> = ({ questions }) => {
  const [question, setQuestion] = useState<Questions | null>(null)

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الموضوع
            </th>
            <th scope="col" className="th-text">
              تاريخ الرسالة
            </th>
            <th scope="col" className="th-text w-[50%]">
              محتوى
            </th>
            <th scope="col" className="th-text text-center">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {questions.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={5}>
                لا توجد بيانات{""}
              </td>
            </tr>
          ) : (
            questions.map((question) => (
              <tr key={question.id} className="hover:bg-gray-50">
                <td className="td-text">{question.subject}</td>
                <td className="td-text">
                  {new Date(question.creationDate).toLocaleDateString("fr-EG")}
                </td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  {limitContent(question.message)}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => setQuestion(question)}
                    className="btn-edit"
                  >
                    <BiSolidShow className="w-5 h-5 " />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {question && (
        <QuestionModal question={question} setQuestion={setQuestion} />
      )}
    </div>
  )
}

export default QuestionTable
