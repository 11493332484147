import apiClient from "../utils/api"
import { UsersData, UsersResponse } from "../types/userManagementTypes"

export const getAllUsers = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
  role: string,
): Promise<UsersResponse> => {
  const response = await apiClient.get(
    `/users/all?&pageNo=${pageNo}&pageSize=${pageSize}&key=${search}&role=${role}`,
  )
  return response.data
}

export const addUser = (usersData: UsersData) => {
  return apiClient.post("/users", usersData)
}

export const editUser = (usersData: UsersData) => {
  return apiClient.put(`/users/${usersData.id}`, usersData)
}

export const deleteUser = (usersData: UsersData) => {
  return apiClient.patch(`/users/${usersData.id}`, {
    status: !usersData.status,
  })
}

export const lockedUser = (usersData: UsersData) => {
  return apiClient.put(`/users/${usersData.id}/locked`, {
    locked: !usersData.locked,
  })
}
