import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { queryClient } from "../../../utils/reactQueryClient"
import useCustomMutation from "../../../hooks/useCustomMutation"
import classNames from "classnames"
import UploadImage from "../../shared/UploadImage"
import { AddMethodModalProps, MethodsData } from "../../../types/methodsTypes"
import { addMethod, editMethod } from "../../../services/methods"
import MyFroalaEditor from "../../shared/MyFroalaEditor"

const MethodModal: React.FC<AddMethodModalProps> = ({ onClose, method }) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<MethodsData>()

  const { mutate: operateMethod } = useCustomMutation({
    mutationFn: method ? editMethod : addMethod,
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ["methods"] })
        onClose()
      }
    },
  })

  const onSubmit = async (data: MethodsData) => {
    operateMethod(data)
  }

  const handleFileUpload = (
    fileName: string,
    field: "coverImgUrl" | "contentImgUrl",
  ) => {
    setValue(field, fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("coverImgUrl", { required: "رابط صورة الغلاف مطلوب" })
    register("contentImgUrl", { required: "رابط صورة المحتوى مطلوب" })
    register("content", { required: "هذا الحقل مطلوب" })
  }, [register])

  useEffect(() => {
    if (method) {
      reset(method)
    }
  }, [method, reset])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">{method ? " تعديل  " : "إضافة  "}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 flex items-center justify-between">
            <div className="w-[49%]">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                العنوان
              </label>
              <input
                {...register("title", { required: "العنوان مطلوب" })}
                id="title"
                className={classNames("input", {
                  "input-invalid": errors.title,
                })}
                placeholder="العنوان"
              />
              {errors.title && (
                <p className="text-red-500 text-xs">{errors.title.message}</p>
              )}
            </div>
            <div className="w-[49%]">
              <label className="block text-sm font-medium text-gray-700">
                الترتيب
              </label>
              <input
                type="number"
                {...register("oradOrder", { required: "هذا الحقل مطلوب" })}
                className={classNames("input", {
                  "input-invalid": errors?.oradOrder,
                })}
                placeholder="ترتيب"
              />
              {errors?.oradOrder && (
                <p className="text-red-500 text-xs">
                  {errors?.oradOrder.message}
                </p>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="authorName"
              className="block text-sm font-medium text-gray-700"
            >
              تحميل صورة الغلاف
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileUrl) =>
                handleFileUpload(fileUrl.fileName, "coverImgUrl")
              }
              ImgUrl={method?.coverImgUrl || null}
              error={errors.coverImgUrl}
              setError={(err) =>
                setError("coverImgUrl", { type: "invalid", message: err })
              }
            />
            {errors.coverImgUrl && (
              <p className="text-red-500 text-xs">
                {errors.coverImgUrl.message}
              </p>
            )}
          </div>

          <div>
            <label
              htmlFor="authorName"
              className="block text-sm font-medium text-gray-700"
            >
              تحميل صورة المحتوى
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileUrl) =>
                handleFileUpload(fileUrl.fileName, "contentImgUrl")
              }
              ImgUrl={method?.contentImgUrl || null}
              error={errors?.contentImgUrl}
              setError={(err) =>
                setError("contentImgUrl", { type: "invalid", message: err })
              }
            />
            {errors?.contentImgUrl && (
              <p className="text-red-500 text-xs">
                {errors?.contentImgUrl.message}
              </p>
            )}
          </div>

          <div className="mt-4">
            <label
              htmlFor="content"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {" "}
              المحتوى
            </label>
            <div className="max-w-full overflow-hidden">
              <MyFroalaEditor
                value={watch("content")}
                onChange={(val) => setValue("content", val)}
                error={errors.content}
              />
            </div>
            {errors.content && (
              <p className="text-red-500 text-xs">{errors.content.message}</p>
            )}
          </div>

          <div className="flex justify-between space-x-reverse space-x-4 pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MethodModal
