import apiClient from "../utils/api"
import {
  NotifcationsData,
  NotifcationsResponse,
} from "../types/notificationsTypes"

export const getAllNotifications = async (
  pageNo: number = 0,
  pageSize: number = 5,
  search: string,
): Promise<NotifcationsResponse> => {
  const response = await apiClient.get(`/recurrence/list`)
  return response.data
}

export const addNotification = (notificationsData: NotifcationsData) => {
  return apiClient.post("/recurrence/schedule", notificationsData)
}

export const editNotification = (notificationsData: NotifcationsData) => {
  return apiClient.put(`/recurrence/${notificationsData.id}`, notificationsData)
}

export const deleteNotification = (notificationId: string) => {
  return apiClient.delete(`/recurrence/${notificationId}`)
}
