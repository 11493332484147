export const youtubeUrlPattern = {
  value:
    /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(&.*)?$/,
  message: "يرجى إدخال رابط يوتيوب صحيح",
}

export const validatePhoneNumber = (value: string) => {
  const isValid = value.length > 10
  return isValid || "يرجى التحقق من رقم الهاتف"
}
