import { useForm } from "react-hook-form"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { addActuality, editActuality } from "../../../services/actuality"
import {
  ActualitysData,
  AddActualitysModalProps,
} from "../../../types/actualityTypes"
import { queryClient } from "../../../utils/reactQueryClient"
import { useEffect } from "react"
import classNames from "classnames"
import UploadImage from "../../shared/UploadImage"
import MyFroalaEditor from "../../shared/MyFroalaEditor"

const ActualityModal: React.FC<AddActualitysModalProps> = ({
  onClose,
  actuality,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
    formState: { errors },
  } = useForm<ActualitysData>()

  const { mutate: operateActuality } = useCustomMutation({
    mutationFn: actuality ? editActuality : addActuality,
    onSuccess: (data) => {
      if (data.status === 200) {
        queryClient.invalidateQueries({ queryKey: ["actuality"] })
        onClose()
      }
    },
  })

  const onSubmit = async (data: ActualitysData) => {
    operateActuality(data)
  }

  const handleFileUpload = (fileName: string) => {
    setValue("imgUrl", fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("imgUrl", { required: "رابط صورة الغلاف مطلوب" })
    register("description", { required: "هذا الحقل مطلوب" })
  }, [register])

  useEffect(() => {
    if (actuality) {
      reset(actuality)
    }
  }, [actuality, reset])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">{actuality ? " تعديل  " : "إضافة  "}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              العنوان
            </label>
            <input
              {...register("title", { required: "العنوان مطلوب" })}
              id="title"
              className={classNames("input", { "input-invalid": errors.title })}
              placeholder="العنوان"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title.message}</p>
            )}
          </div>

          <div>
            <label
              htmlFor="authorName"
              className="block text-sm font-medium text-gray-700"
            >
              تحميل الصورة
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileUrl) => handleFileUpload(fileUrl.fileName)}
              ImgUrl={actuality?.imgUrl || null}
              error={errors?.imgUrl}
              setError={(err) =>
                setError("imgUrl", { type: "invalid", message: err })
              }
            />
            {errors?.imgUrl && (
              <p className="text-red-500 text-xs">{errors?.imgUrl?.message}</p>
            )}
          </div>

          <div className="mt-4">
            <label
              htmlFor="content"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              {" "}
              المحتوى
            </label>
            <div className="max-w-full overflow-hidden">
              <MyFroalaEditor
                value={watch("description")}
                onChange={(val) => setValue("description", val)}
                error={errors.description}
              />
            </div>
            {errors.description && (
              <p className="text-red-500 text-xs">
                {errors.description.message}
              </p>
            )}
          </div>

          <div className="flex justify-between space-x-reverse space-x-4 pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ActualityModal
