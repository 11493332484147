import React, { forwardRef, useEffect, useState } from "react"
import FroalaEditorComponent from "react-froala-wysiwyg"
import "froala-editor/css/froala_style.min.css"
import "froala-editor/css/froala_editor.pkgd.min.css"
import "froala-editor/js/plugins.pkgd.min.js"
import "../../style/TextEditeur.css"
import "froala-editor/js/languages/ar.js"
import { colors } from "../../constants"

interface TextEditeurProps {
  value: string
  onChange: (content: string) => void
  disabled?: boolean
  error?: any
}

const MyFroalaEditor = forwardRef<HTMLDivElement, TextEditeurProps>(
  (props, ref) => {
    const { value, onChange, disabled = false, error } = props
    const [isEditorReady, setIsEditorReady] = useState(false)

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsEditorReady(true)
      }, 500) //

      return () => clearTimeout(timer)
    }, [])

    const handleModelChange = (model: string) => {
      let withoutFroala = model.replace(
        /<p[^>]*>Powered by <a[^>]*>Froala Editor<\/a><\/p>/gi,
        "",
      )
      onChange(withoutFroala)
    }

    if (!isEditorReady) {
      return <div>Loading Editor...</div> // Display a loading message or a spinner
    }

    return (
      <div
        className="froala-editor"
        ref={ref}
        style={error ? { border: "1px solid red" } : {}}
      >
        <FroalaEditorComponent
          tag="textarea"
          model={value}
          onModelChange={handleModelChange}
          config={{
            attribution: false,

            toolbarButtons: disabled
              ? []
              : [
                  "bold",
                  "italic",
                  "underline",
                  "strikeThrough",
                  "|",
                  "textColor",
                  "backgroundColor",
                  "fontFamily",
                  "fontSize",
                  "color",
                  "|",
                  // "paragraphFormat",
                  "align",
                  "formatOL",
                  "formatUL",
                  "|",
                  "html",
                  "undo",
                  "redo",
                ],
            language: "ar",
            fontFamily: {
              AmiriQuranRegular: "Amiri Quran Regular",
              CairoBlack: "Cairo Black",
              CairoBold: "Cairo Bold",
              CairoSemiBold: "Cairo SemiBold",
              CairoExtraLight: "Cairo ExtraLight",
              CairoLight: "Cairo Light",
              CairoRegular: "Cairo Regular",
            },
            fontFamilySelection: ["true"],
            fontSize: ["8", "10", "12", "14", "18", "24", "30", "36", "48"],
            colorsBackground: colors,
            colorsText: colors,
            heightMin: 100,
            heightMax: 300,
            placeholderText: "ابدأ الكتابة...",
          }}
        />
      </div>
    )
  },
)

export default MyFroalaEditor
