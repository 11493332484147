import { useState } from "react"
import useDebounceState from "../../hooks/useDebounceState"
import SearchInput from "../../components/shared/SearchInput"
import { useQuery } from "@tanstack/react-query"
import Loading from "../../components/shared/Loading"
import ActualityTable from "../../components/actuality/table/ActualityTable"
import Pagination from "../../components/shared/Pagination"
import { getAllAmdahs } from "../../services/amdah"
import AmdahModal from "../../components/amdah/modal/AmdahModal"
import AmdahsTable from "../../components/amdah/table/AmdahTable"

const AmdahListPage = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [showAddAmdahModal, setShowAddAmdahModal] = useState(false)
  const pageSize = 5

  const {
    debouncedValue: search,
    setValue: setSearch,
    value: searchState,
  } = useDebounceState("")

  const {
    data: amdahData,
    isError,
    isPending: isLoading,
  } = useQuery({
    queryKey: ["amdah", currentPage, search],
    queryFn: () => getAllAmdahs(currentPage, pageSize, search),
  })

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const totalCount = amdahData?.totalElements ?? 0
  const toggleAddAmdahModal = () => setShowAddAmdahModal(!showAddAmdahModal)

  if (isError) {
    return <div>Error fetching Amdah</div>
  }

  return (
    <div className="max-w-5xl mx-auto">
      <header className="mb-7">
        <h1 className="title">سماعنا وإنشادنا</h1>
        <div className="flex justify-between items-center mt-5">
          <SearchInput
            searchValue={searchState}
            handleChange={(e) => setSearch(e.target.value)}
          />
          <button onClick={toggleAddAmdahModal} className="px-4 py-2 btn-add">
            اضافة سماع
          </button>
        </div>
      </header>

      {showAddAmdahModal && <AmdahModal onClose={toggleAddAmdahModal} />}
      {isLoading ? (
        <Loading />
      ) : (
        <AmdahsTable amdahs={amdahData?.content || []} />
      )}

      <div className="mt-8">
        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  )
}

export default AmdahListPage
