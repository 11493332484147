import { useEffect } from "react"
import { useForm } from "react-hook-form"
import { DefinitionData } from "../../../types/definitionsTypes"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { addDefinition, editDefinition } from "../../../services/definitions"
import { queryClient } from "../../../utils/reactQueryClient"
import classNames from "classnames"
import MyFroalaEditor from "../../shared/MyFroalaEditor"
import UploadImage from "../../shared/UploadImage"

interface DefinitionModalProps {
  onClose: () => void
  definition?: DefinitionData | null
}

const DefinitionModal: React.FC<DefinitionModalProps> = ({
  onClose,
  definition,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    reset,
    formState: { errors },
    watch,
  } = useForm<DefinitionData>()

  const { mutate: operateDefinition } = useCustomMutation({
    mutationFn: definition ? editDefinition : addDefinition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["definitionBycategories"] })
      onClose()
    },
  })

  useEffect(() => {
    if (definition) {
      reset(definition)
    }
  }, [definition, reset])

  const onSubmit = (data: DefinitionData) => {
    operateDefinition(data)
  }

  const handleFileUpload = (fileName: string) => {
    setValue("coverImgUrl", fileName, { shouldValidate: true })
  }

  useEffect(() => {
    register("coverImgUrl", { required: "رابط صورة الغلاف مطلوب" })
    register("content", { required: "هذا الحقل مطلوب" })
  }, [register])

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      dir="rtl"
    >
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">
          {definition ? " تعديل تعريف " : "إضافة تعريف جديد "}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="coverImgUrl"
              className="block text-sm font-medium text-gray-700"
            >
              تحميل الصورة
              <span className="text-xs text-red-500 pr-2">
                ( يستحسن ان يكون ارتفاع الصورة 250 بكسل )
              </span>
            </label>
            <UploadImage
              onFileUpload={(fileUrl) => handleFileUpload(fileUrl.fileName)}
              ImgUrl={definition?.coverImgUrl || null}
              error={errors.coverImgUrl}
              setError={(err) =>
                setError("coverImgUrl", { type: "invalid", message: err })
              }
            />
            {errors.coverImgUrl && (
              <p className="text-red-500 text-xs">
                {errors.coverImgUrl.message}
              </p>
            )}
          </div>
          <div className="mb-4 flex items-center justify-between">
            <div className="w-[49%]">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                العنوان
              </label>
              <input
                {...register("title", { required: "هذا الحقل مطلوب" })}
                id="title"
                className={classNames("input", {
                  "input-invalid": errors.title,
                })}
                placeholder="العنوان"
              />
              {errors.title && (
                <p className="text-red-500 text-xs">{errors.title.message}</p>
              )}
            </div>
            <div className="w-[49%]">
              <label className="block text-sm font-medium text-gray-700">
                الترتيب
              </label>
              <input
                type="number"
                {...register("definitionOrder", {
                  required: "هذا الحقل مطلوب",
                })}
                className={classNames("input", {
                  "input-invalid": errors?.definitionOrder,
                })}
                placeholder="ترتيب"
              />
              {errors?.definitionOrder && (
                <p className="text-red-500 text-xs">
                  {errors?.definitionOrder.message}
                </p>
              )}
            </div>
          </div>

          <div className="mt-4">
            <label
              htmlFor="content"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              المحتوى
            </label>
            <div className="max-w-full overflow-hidden">
              <MyFroalaEditor
                value={watch("content")}
                onChange={(val) => setValue("content", val)}
                error={errors.content}
              />
            </div>
            {errors.content && (
              <p className="text-red-500 text-xs">{errors.content.message}</p>
            )}
          </div>

          <div className="flex justify-between pt-8">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DefinitionModal
