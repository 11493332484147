export const removeHtmlTags = (str: string) => {
  let withoutStyles = str.replace(/style="[^"]*"/g, "")
  let withoutTags = withoutStyles.replace(/<\/?[^>]+(>|$)/g, "")
  let withoutEntities = withoutTags
    .replace(/&nbsp;/g, " ")
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
  return withoutEntities
}

export const formatDateToISO = (dateString: string) => {
  if (!dateString) {
    return ""
  }

  const date = new Date(dateString)
  if (isNaN(date.getTime())) {
    return ""
  }

  date.setHours(23, 59, 0, 0)
  const isoDateString = date.toISOString()
  return isoDateString
}

export const filterByExtensions = (
  data: string[] | undefined,
  extensions: string[],
): string[] => {
  return (
    data?.filter((file) =>
      extensions.some((extension) => file.endsWith(extension)),
    ) || []
  )
}

export const checkExtension = (extension: string) => {
  return extension?.split(".").pop()?.toLowerCase()
}

export const limitContent = (content: string) => {
  return removeHtmlTags(content).substring(0, 99) + "..."
}

export function isValidUrl(url: string): boolean {
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}
