import LoadingImage from "../../assets/images/Loading.svg"

const Loading = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center h-full">
      <img
        src={LoadingImage}
        className="mx-auto h-14 w-auto bg-transparent"
        alt="Your Logo"
      />
    </div>
  )
}

export default Loading
