import { IoMdArrowRoundBack } from "react-icons/io"
import { useNavigate } from "react-router-dom"

const BackButton = () => {
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(-1)
  }

  return (
    <button onClick={handleNavigate} className="p-2 btn-add">
      <IoMdArrowRoundBack className="h-7 w-7" />
    </button>
  )
}

export default BackButton
